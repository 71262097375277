import { info, error, primary, success, warning, secondary } from 'src/theme/palette';

// ----------------------------------------------------------------------

export const CALENDAR_COLOR_OPTIONS = [
  primary.main,
  secondary.main,
  info.main,
  info.darker,
  success.main,
  warning.main,
  error.main,
  error.darker,
];

export const mockAppointments = [
  {
    doctorAppointmentId: '1',
    doctorId: '101',
    patientId: '201',
    hasInsurance: true,
    name: 'Juan',
    lastName: 'Pérez',
    documentNumber: '123456789',
    documentType: 1,
    emailAddress: 'juan.perez@example.com',
    gender: 'Male',
    nationality: 'Dominican',
    isDeleted: false,
    dateOfBirth: new Date('1990-04-12').toISOString(),
    status: 1,
    title: 'Consulta de Juan Pérez',
    description: 'Consulta de control',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-20T10:00:00Z').toISOString(),
    end: new Date('2024-10-20T12:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[0],
    allDay: false,
  },
  {
    doctorAppointmentId: '2',
    doctorId: '102',
    patientId: '202',
    hasInsurance: false,
    name: 'María',
    lastName: 'Gómez',
    documentNumber: '987654321',
    documentType: 2,
    emailAddress: 'maria.gomez@example.com',
    gender: 'Female',
    nationality: 'Mexican',
    isDeleted: false,
    dateOfBirth: new Date('1985-07-22').toISOString(),
    status: 1,
    title: 'Consulta de María Gómez',
    description: 'Revisión general',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-21T14:00:00Z').toISOString(),
    end: new Date('2024-10-21T16:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[1],
    allDay: false,
  },
  {
    doctorAppointmentId: '3',
    doctorId: '103',
    patientId: '203',
    hasInsurance: true,
    name: 'Carlos',
    lastName: 'Rodríguez',
    documentNumber: '1122334455',
    documentType: 1,
    emailAddress: 'carlos.rodriguez@example.com',
    gender: 'Male',
    nationality: 'Colombian',
    isDeleted: false,
    dateOfBirth: new Date('1995-12-30').toISOString(),
    status: 1,
    title: 'Consulta de Carlos Rodríguez',
    description: 'Chequeo médico',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-22T09:00:00Z').toISOString(),
    end: new Date('2024-10-22T11:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[2],
    allDay: false,
  },
  {
    doctorAppointmentId: '4',
    doctorId: '104',
    patientId: '204',
    hasInsurance: false,
    name: 'Ana',
    lastName: 'Martínez',
    documentNumber: '5566778899',
    documentType: 1,
    emailAddress: 'ana.martinez@example.com',
    gender: 'Female',
    nationality: 'Venezuelan',
    isDeleted: false,
    dateOfBirth: new Date('1993-02-18').toISOString(),
    status: 1,
    title: 'Consulta de Ana Martínez',
    description: 'Consulta por dolor de cabeza',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-23T11:30:00Z').toISOString(),
    end: new Date('2024-10-23T13:30:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[3],
    allDay: false,
  },
  {
    doctorAppointmentId: '5',
    doctorId: '105',
    patientId: '205',
    hasInsurance: true,
    name: 'Luis',
    lastName: 'Fernández',
    documentNumber: '6677889900',
    documentType: 2,
    emailAddress: 'luis.fernandez@example.com',
    gender: 'Male',
    nationality: 'Chilean',
    isDeleted: false,
    dateOfBirth: new Date('2000-09-14').toISOString(),
    status: 1,
    title: 'Consulta de Luis Fernández',
    description: 'Consulta preventiva',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-24T16:00:00Z').toISOString(),
    end: new Date('2024-10-24T18:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[4],
    allDay: false,
  },
  {
    doctorAppointmentId: '6',
    doctorId: '106',
    patientId: '206',
    hasInsurance: false,
    name: 'Sofía',
    lastName: 'López',
    documentNumber: '2233445566',
    documentType: 1,
    emailAddress: 'sofia.lopez@example.com',
    gender: 'Female',
    nationality: 'Argentinian',
    isDeleted: false,
    dateOfBirth: new Date('1988-11-11').toISOString(),
    status: 1,
    title: 'Consulta de Sofía López',
    description: 'Control de embarazo',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-25T12:00:00Z').toISOString(),
    end: new Date('2024-10-25T14:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[0],
    allDay: false,
  },
  {
    doctorAppointmentId: '7',
    doctorId: '107',
    patientId: '207',
    hasInsurance: true,
    name: 'Ricardo',
    lastName: 'Díaz',
    documentNumber: '8899001122',
    documentType: 2,
    emailAddress: 'ricardo.diaz@example.com',
    gender: 'Male',
    nationality: 'Peruvian',
    isDeleted: false,
    dateOfBirth: new Date('1979-03-28').toISOString(),
    status: 1,
    title: 'Consulta de Ricardo Díaz',
    description: 'Chequeo de diabetes',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-26T15:00:00Z').toISOString(),
    end: new Date('2024-10-26T17:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[1],
    allDay: false,
  },
  {
    doctorAppointmentId: '8',
    doctorId: '108',
    patientId: '208',
    hasInsurance: false,
    name: 'Gabriela',
    lastName: 'Ramos',
    documentNumber: '3344556677',
    documentType: 1,
    emailAddress: 'gabriela.ramos@example.com',
    gender: 'Female',
    nationality: 'Brazilian',
    isDeleted: false,
    dateOfBirth: new Date('1992-06-07').toISOString(),
    status: 1,
    title: 'Consulta de Gabriela Ramos',
    description: 'Revisión por alergias',
    createdDate: new Date().toISOString(),
    start: new Date('2024-10-27T10:00:00Z').toISOString(),
    end: new Date('2024-10-27T12:00:00Z').toISOString(),
    updatedDate: new Date().toISOString(),
    createdBy: 'admin',
    updatedBy: 'admin',
    color: CALENDAR_COLOR_OPTIONS[2],
    allDay: false,
  },
];
