import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Portal Pacientes',
    icon: <Iconify icon="solar:login-bold-duotone" />,
    path: paths.patient.login,
  },
  {
    title: 'Resultados',
    icon: <Iconify icon="solar:login-bold-duotone" />,
    path: paths.patient.results,
  },
  {
    title: 'Citas',
    icon: <Iconify icon="solar:login-bold-duotone" />,
    path: paths.patient.appointments,
  },
];
