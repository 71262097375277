import axios from '../../utils/axios';

export const getJobPositions = async () => {
  const response = await axios.get(`/api/JobInfo`);
  return response;
};

export const getJobPositionById = async (id) => {
  const response = await axios.get(`/api/JobInfo/${id}`);

  return response;
};

export const createJobPosition = async (payload) => {
  const response = await axios.post('/api/JobInfo', payload);
  return response;
};

export const updateJobPosition = async (id, payload) => {
  const response = await axios.put(`/api/JobInfo/${id}`, payload);
  return response;
};

export const deleteJobPosition = async (id) => {
  const response = await axios.delete(`/api/JobInfo/${id}`);
  return response;
};
