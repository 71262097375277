import React, { useMemo, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import {
  Card,
  Grid,
  Dialog,
  Typography,
  CardContent,
  DialogTitle,
  DialogContent,
  CardActionArea,
} from '@mui/material';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify/index';
import { useSettingsContext } from 'src/components/settings/index';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/index';

// import EmployeesModal from './employees-detail-view';
import EmployeeNewEditForm from 'src/sections/human-resources/employees/employee-new-edit-form';

export default function EmployeesGridView() {
  const employees = [
    { id: 1, name: 'Juan Pérez', position: 'Desarrollador', department: 'TI' },
    { id: 2, name: 'María García', position: 'Diseñadora', department: 'Diseño' },
    { id: 3, name: 'Pedro Martínez', position: 'Gerente de Proyecto', department: 'Gestión' },
    { id: 4, name: 'Laura Sánchez', position: 'Analista de Datos', department: 'TI' },
    { id: 5, name: 'Carlos López', position: 'Ingeniero de Software', department: 'TI' },
    { id: 6, name: 'Ana Rodríguez', position: 'Diseñadora Gráfica', department: 'Diseño' },
    {
      id: 7,
      name: 'José González',
      position: 'Especialista en Marketing',
      department: 'Marketing',
    },
    { id: 8, name: 'Marta Fernández', position: 'Contadora', department: 'Finanzas' },
    { id: 9, name: 'Roberto Gómez', position: 'Analista de Sistemas', department: 'TI' },
    { id: 10, name: 'Sofía Ramírez', position: 'Recursos Humanos', department: 'Recursos Humanos' },
    { id: 11, name: 'Luisa Navarro', position: 'Desarrolladora Frontend', department: 'TI' },
    {
      id: 12,
      name: 'Daniel Torres',
      position: 'Diseñador de Experiencia de Usuario',
      department: 'Diseño',
    },
    { id: 13, name: 'Elena Jiménez', position: 'Analista Financiero', department: 'Finanzas' },
    { id: 14, name: 'Javier Ruiz', position: 'Director de Operaciones', department: 'Operaciones' },
    { id: 15, name: 'Lucía Vargas', position: 'Especialista en SEO', department: 'Marketing' },
  ];

  const settings = useSettingsContext();
  const newEmployeeView = useBoolean();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleEmployeeSelect = useCallback(
    (employee) => {
      setSelectedEmployee(employee);
      newEmployeeView.onTrue();
    },
    [newEmployeeView]
  );

  const handleResetEmployee = useCallback(() => {
    setSelectedEmployee(null);
  }, [setSelectedEmployee]);

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangeDepartment = (event) => {
    setSelectedDepartment(event.target.value);
    setSelectedEmployee(null);
  };

  const filteredEmployees = employees.filter((employee) => {
    const matchesSearchTerm = employee.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDepartment =
      selectedDepartment === '' || employee.department === selectedDepartment;
    return matchesSearchTerm && matchesDepartment;
  });

  const departments = [...new Set(employees.map((employee) => employee.department))];

  const renderEmployees = useMemo(
    () =>
      filteredEmployees.map((employee) => (
        <Grid item key={employee.id} xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardActionArea onClick={() => handleEmployeeSelect(employee)}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {employee.name}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  {employee.position}
                </Typography>
                <Typography variant="body2" component="p">
                  Departamento: {employee.department}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )),
    [filteredEmployees, handleEmployeeSelect]
  );

  return (
    <div>
      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CustomBreadcrumbs
          heading="Lista de Empleados"
          links={[{ name: 'Medicore', href: paths.medicore.root }, { name: 'Empleados' }]}
          action={
            <Button
              onClick={newEmployeeView.onTrue}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              Nuevo Empleado
            </Button>
          }
          sx={{
            mb: {
              xs: 3,
              md: 5,
            },
          }}
        />

        <Grid container spacing={2} alignItems="center" sx={{ mb: 5 }}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Buscar empleado"
              variant="outlined"
              value={searchTerm}
              onChange={handleChangeSearchTerm}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              label="Filtrar por departamento"
              variant="outlined"
              value={selectedDepartment}
              onChange={handleChangeDepartment}
              fullWidth
            >
              <MenuItem value="">Todos</MenuItem>
              {departments.map((department) => (
                <MenuItem key={department} value={department}>
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
        >
          {renderEmployees}
        </Box>
      </Container>

      <Dialog
        open={newEmployeeView.value}
        onClose={(event, reason) => newEmployeeView.onCloseNotBackdrop(event, reason)}
        fullWidth="md"
        maxWidth="md"
      >
        <DialogTitle>Empleado: Nombre Empleado</DialogTitle>
        <DialogContent>
          <EmployeeNewEditForm
            employee={selectedEmployee}
            openCloseModal={newEmployeeView}
            reset={handleResetEmployee}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
