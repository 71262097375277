import { countries } from 'src/assets/data';

import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const JOB_DETAILS_TABS = [
  { value: 'content', label: 'Detalles de la vacante' },
  { value: 'candidates', label: 'Candidatos' },
];

export const JOB_SKILL_OPTIONS = [
  'UI',
  'UX',
  'Html',
  'JavaScript',
  'TypeScript',
  'Communication',
  'Problem Solving',
  'Leadership',
  'Time Management',
  'Adaptability',
  'Collaboration',
  'Creativity',
  'Critical Thinking',
  'Technical Skills',
  'Customer Service',
  'Project Management',
  'Problem Diagnosis',
];

export const JOB_WORKING_SCHEDULE_OPTIONS = [
  'Monday to Friday',
  'Weekend availability',
  'Day shift',
];

export const JOB_EMPLOYMENT_TYPE_OPTIONS = [
  { value: 'Tiempo completo', label: 'Tiempo completo' },
  { value: 'Medio tiempo', label: 'Medio tiempo' },
  { value: 'A demanda', label: 'A demanda' },
  { value: 'Negociable', label: 'Negociable' },
];

export const JOB_EXPERIENCE_OPTIONS = [
  { value: 'No experience', label: 'Sin experiencia' },
  { value: '1 year exp', label: '1 año' },
  { value: '2 year exp', label: '2 años' },
  { value: '> 3 year exp', label: 'Más de 3 años' },
];

export const JOB_BENEFIT_OPTIONS = [
  { value: 'Free parking', label: 'Estacionamiento gratuito' },
  { value: 'Bonus commission', label: 'Comisión por bonificación' },
  { value: 'Travel', label: 'Viajes' },
  { value: 'Device support', label: 'Soporte de dispositivos' },
  { value: 'Health care', label: 'Cuidado de la salud' },
  { value: 'Training', label: 'Capacitación' },
  { value: 'Health Insurance', label: 'Seguro de salud' },
  { value: 'Retirement Plans', label: 'Planes de jubilación' },
  { value: 'Paid Time Off', label: 'Tiempo libre pagado' },
  { value: 'Flexible Work Schedule', label: 'Horario de trabajo flexible' },
];

export const JOB_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const JOB_SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

const CANDIDATES = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  role: _mock.role(index),
  name: _mock.fullName(index),
  avatarUrl: _mock.image.avatar(index),
}));

export const CONTENT = `
<h6>Descripción del Trabajo</h6>
<br/>

<p>Resumen de funciones sobre la posición de trabajo.</p>

<br/>
<br/>

<h6>Responsabilidades</h6>
<br/>
<ul>
  <li>Atribución #1.</li>
  <li>Atribución #2.</li>
  <li>Atribución #3.</li>
  <li>Atribución #4.</li>
  <li>Atribución #5.</li>
</ul>

<br/>
<br/>

<h6>Habilidades necesarias</h6>
<br/>
<ul>
  <li>Habilidad #1 (excluyente, no excluyente).</li>
  <li>Habilidad #2 (excluyente, no excluyente).</li>
  <li>Habilidad #3 (excluyente, no excluyente).</li>
  <li>Habilidad #4 (excluyente, no excluyente).</li>
  <li>Habilidad #5 (excluyente, no excluyente).</li>
</ul>
`;

export const _jobs = [...Array(12)].map((_, index) => {
  const publish = index % 3 ? 'published' : 'draft';

  const salary = {
    type: (index % 5 && 'Custom') || 'Hourly',
    price: _mock.number.price(index),
    negotiable: _mock.boolean(index),
  };

  const benefits = JOB_BENEFIT_OPTIONS.slice(0, 3).map((option) => option.label);

  const experience =
    JOB_EXPERIENCE_OPTIONS.map((option) => option.label)[index] || JOB_EXPERIENCE_OPTIONS[1].label;

  const employmentTypes = (index % 2 && ['Part-time']) ||
    (index % 3 && ['On Demand']) ||
    (index % 4 && ['Negotiable']) || ['Full-time'];

  const company = {
    name: _mock.companyName(index),
    logo: _mock.image.company(index),
    phoneNumber: _mock.phoneNumber(index),
    fullAddress: _mock.fullAddress(index),
  };

  const locations = countries.slice(1, index + 2).map((option) => option.label);

  return {
    id: _mock.id(index),
    salary,
    publish,
    company,
    benefits,
    locations,
    experience,
    employmentTypes,
    content: CONTENT,
    candidates: CANDIDATES,
    role: _mock.role(index),
    title: _mock.jobTitle(index),
    createdAt: _mock.time(index),
    expiredDate: _mock.time(index),
    skills: JOB_SKILL_OPTIONS.slice(0, 3),
    totalViews: _mock.number.nativeL(index),
    workingSchedule: JOB_WORKING_SCHEDULE_OPTIONS.slice(0, 2),
  };
});
