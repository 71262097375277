import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: sx?.width || 210,
        height: sx?.height || 60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2223.92 792.72"
      >
        <g>
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M567.39,421.5c-17.43,41.37-34.38,81.59-51.32,121.81-.62.03-1.25.07-1.87.1-17.14-39.75-34.28-79.51-51.43-119.26l-1.54.26c-.14,1.8-.41,3.59-.41,5.39-.02,29.69-.03,59.39.02,89.08.03,15.59,2.05,18,17.14,21.27.81.18,1.51.86,2.07,2.44h-44.78c-.08-.55-.16-1.1-.24-1.65,1.68-.35,3.36-.76,5.05-1.05,8.64-1.51,13.46-6.26,13.51-15.04.2-33.6.16-67.2-.05-100.79-.05-7.97-3.94-11.48-11.96-12.31-2.16-.22-4.33-.4-6.5-.59-.04-.64-.08-1.27-.12-1.91,1.64-.29,3.27-.83,4.91-.83,14.85-.06,29.69.13,44.54-.11,4.29-.07,6.12,1.68,7.69,5.42,10.7,25.56,21.62,51.03,32.49,76.52.82,1.92,1.79,3.78,3.32,7.02,1.51-3.05,2.59-4.94,3.41-6.93,10.58-25.62,21.21-51.21,31.56-76.92,1.6-3.97,3.65-5.16,7.77-5.1,14.45.23,28.91.05,43.37.06,1.8,0,3.61.22,5.41.33l.21,1.83c-1.57.38-3.13,1-4.73,1.11-10.58.69-14.02,3.57-14.09,13.82-.22,33.79-.2,67.59-.03,101.38.05,9.14,3.83,12.55,12.92,13.21,1.93.14,3.85.51,5.77.78-.04.57-.09,1.13-.13,1.7h-69.88c-.04-.56-.08-1.13-.11-1.69,1.97-.24,3.93-.58,5.9-.72,8.99-.6,13.12-4.2,13.17-13.08.21-34.18.1-68.37.08-102.56,0-.5-.36-.99-1.13-2.97Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M264.35,487.67h64.13c.11.55.22,1.1.33,1.66-1.6.49-3.16,1.13-4.79,1.43-7.46,1.38-10.53,4.6-10.7,12.24-.21,9.18-.17,18.36-.02,27.54.05,3.17-.88,4.85-4.01,6.08-22.66,8.97-46.06,12.33-70.12,8.47-33.2-5.32-57.3-32.34-58.37-64.46-1.15-34.63,20.86-64.59,53.53-72.7,18.74-4.65,37.16-3.72,55.11,3.74,2.16.9,4.37,1.7,6.6,2.39,5.81,1.8,9.27.35,12.22-4.84.84-1.48,1.96-2.8,2.95-4.2.48.19.95.38,1.43.57v46.56c-.48.2-.96.41-1.44.61-.95-1.55-2.18-2.99-2.82-4.66-8.64-22.29-27.63-35.96-49.46-35.49-17.29.37-31.18,10.34-36.66,26.87-7.85,23.66-8.25,47.62-1.34,71.53,6.22,21.51,27.71,33.07,49.96,27.42,1.89-.48,3.74-1.18,5.66-1.51,3.45-.6,4.95-2.37,4.87-6.01-.2-9.18.02-18.36-.2-27.54-.2-8.42-3.72-12.03-11.98-13-1.75-.21-3.47-.55-5.21-.83.11-.62.22-1.24.33-1.86Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M403.47,450.97c3.17-1.49,6.4-2.78,9.41-4.46,10.66-5.95,11.35-17.48.56-23.43-6.61-3.65-14.56-5.52-22.13-6.66-10.39-1.56-21.03-1.44-31.51-2.47-14.92-1.47-28.16-7.09-37.8-18.83-13.14-16-7.46-41.51,11.62-50.29,8.14-3.74,17.49-5.33,26.47-6.6,7.17-1.01,12.42,4.01,16.7,9.27,2.6,3.19-.27,5.68-2.7,6.22-5.75,1.28-11.9,2.95-17.52,2.09-10.58-1.62-17.56-.1-20.18,7.75-4.21,12.61-3.59,19.11,6.26,25.75,9.87,6.67,21.33,6.73,32.56,8.14,12.72,1.6,25.7,2.74,37.9,6.34,15.44,4.56,24.07,17.05,24.46,30.96.31,11.17-5.38,18.82-14.37,24.45-1.08.67-3.1.87-4.2.32-5.18-2.62-10.2-5.57-15.52-8.55Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M450.44,310.92c-6.5,10.41-16.02,6.69-24.91,7.23,1.65.98,3.3,1.96,5.55,3.29-14.41,9.46-29.59,11.71-45.82,9.83,1.66-9.46,2.99-10.78,11.96-12.25,9.75-1.6,16.26-8.8,16.75-19,.26-5.45-.19-10.94-.03-16.4.27-9.19,4.82-14.25,13.97-13.23,7.99.9,16.21,3.01,23.47,6.42,12.85,6.02,25.07,13.41,37.52,20.29.96.53,1.67,1.48,3.21,2.89-15.48,13.24-32.78,8.29-49.85,7.47,2.7,1.14,5.4,2.28,8.17,3.46Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M381.03,331.48c-16.73,1.38-32.02-.47-46.47-10.15,2.32-1.24,3.93-2.1,5.53-2.96-.07-.43-.14-.87-.21-1.3-4.46.46-9.07,1.84-13.32,1.12-3.99-.68-7.61-3.59-11.39-5.51.16-.58.33-1.16.49-1.74,2.36-1.07,4.72-2.14,7.08-3.21-16.4-1.23-34.07,6.79-49.11-8.3,3.45-2.35,6.31-4.52,9.38-6.35,15.1-8.99,30.28-17.86,47.6-22.03,2.8-.67,5.8-.89,8.69-.76,8.16.35,12.43,4.91,12.56,13.07.09,6.04-.29,12.13.25,18.13.86,9.58,6.41,16.52,15.53,17.06,8.56.51,11.24,5.31,13.39,12.94Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M358.88,419.05c-2.98,1.99-5.32,3.32-7.4,4.98-8.11,6.46-7.79,16.11.72,21.83,8.86,5.96,19.16,5.94,29.22,7.13,8.27.98,16.79,1.61,24.61,4.2,13.22,4.37,20.78,17.59,19.26,31.42-.31,2.84-1.25,5.76-2.54,8.32-4.21,8.29-8.01,9.33-16.18,4.87-2.02-1.1-4-2.26-6.38-3.6,1.12-.72,1.72-1.23,2.41-1.52,5.36-2.22,9.81-5.1,9.08-11.95-.76-7.11-4.31-12.41-11.23-14.16-5.79-1.46-11.9-2.02-17.9-2.21-11.61-.36-23-1.48-33.78-6.15-13-5.63-20.35-15.77-19.69-27.68.2-3.64,1.15-7.34,2.39-10.79,3.35-9.34,6.23-10.67,15.51-7.95,3.67,1.07,7.39,2.02,11.91,3.25Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M385.23,165.85c-5.13-.53-10.26-1.05-15.38-1.58,5.46-1.88,6.78-5.81,7.24-10.94,1.15-12.82,2.72-25.61,4.23-38.4.55-4.62-.3-8.23-5.43-10.35,6.91.88,13.86,1.51,20.67,2.86,1.32.26,2.46,3.2,3.06,5.11,3.31,10.41,6.41,20.89,9.62,31.34.27.87.78,1.67,1.71,3.62,2.2-3.71,4-6.56,5.62-9.5,4.31-7.88,8.63-15.75,12.74-23.73,1.46-2.84,3.21-3.95,6.47-3.46,5.76.88,11.59,1.35,15.91,1.83-1.74,2.51-4.92,5.07-5.32,8.01-1.95,14.3-3.44,28.69-4.38,43.09-.18,2.79,2.77,5.78,4.08,8.29-7.46-.76-16.32-1.66-25.18-2.55,5.33-1.2,6.87-4.57,7.27-9.55.93-11.68,2.34-23.31,3.52-34.97.21-2.07.26-4.15-.99-6.69-8.95,16.15-17.9,32.31-27.65,49.89-6.1-19.27-11.74-37.1-17.38-54.93-.42.05-.84.09-1.26.14-.98,8.82-1.99,17.63-2.94,26.46-.52,4.85-.87,9.73-1.44,14.57-.56,4.71.27,8.6,5.23,11.44Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M294,314.44c-15.54,4.92-30.89,10.67-46.69,14.52-13.35,3.25-27.18,5.1-40.9,6.03-7.13.48-14.67-1.79-21.66-3.98-3.9-1.22-7.06-4.83-10.55-7.36.21-.65.43-1.3.64-1.96,3.66-.64,7.35-1.17,10.98-1.95,26.06-5.63,52.13-11.22,78.14-17.09,3.43-.77,5.2-.63,7.88,2.2,2.86,3.02,7.37,4.77,11.45,6.25,3.21,1.16,6.88,1.04,10.34,1.5.12.62.24,1.24.36,1.86Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M591.15,322.06c-8.77,8.94-18.03,13.01-28.76,13.16-19.13.26-37.52-4.35-55.69-9.72-11.74-3.47-23.24-7.72-34.71-12.6,4.76-.98,9.58-1.74,14.25-3.04,2.35-.66,5.45-1.75,6.44-3.6,3.1-5.76,6.98-3.89,11.72-2.83,26.8,5.99,53.69,11.58,80.55,17.31,1.61.34,3.22.69,6.2,1.32Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M406.89,396.52c5.95-2.54,11.55-4.32,16.53-7.18,11.12-6.38,12.53-21.65,3.21-30.46-4.38-4.14-9.27-5.39-15.05-3.31-7.3,2.63-14.16,1.36-20.65-2.7-3.14-1.97-2.99-3.77-.64-6.53,7.71-9.08,17.39-8.66,27.84-6.87,21.32,3.64,34.27,18.22,33.03,38.47-.79,12.87-8.6,21.35-19.12,27.64-1.34.8-3.67,1.07-5.05.47-6.69-2.92-13.21-6.21-20.1-9.53Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M169.76,282.64c-4.49,6.79-8.99,13.58-13.48,20.36.41-5.02-2.2-7.63-6-10.06-10.84-6.94-21.46-14.2-32.15-21.37-4.05-2.72-8.06-5-12.3.82-.24-.55-.48-1.1-.72-1.65,7.6-10.52,14.86-21.3,22.92-31.45,4.97-6.27,12.16-7.42,18.39-4.52,6.65,3.1,9.05,8.05,8.22,16.91-.09.94-.01,1.89-.01,3.98,11.52-.61,22.8-1.21,33.63-1.79-2.31,8.96-8.36,13.78-18.77,13.96-6.44.11-12.88.21-19.3.69-1.86.14-3.61,1.57-5.42,2.41,1.08,1.4,1.91,3.13,3.28,4.15,3.27,2.45,6.86,4.49,10.2,6.86,3.67,2.6,7.24,4.07,11.51.69ZM119.55,256.23c6.46,4.31,13.01,8.75,19.68,13.02.71.45,2.36.26,3.1-.28,6.22-4.54,6.58-14.45.83-19.54-9.31-8.22-17.77-6.01-23.6,6.8Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M504.24,195.01c5.25,3.3,8.19.78,10.94-3.2,7.64-11.09,15.37-22.13,23.28-33.03,2.78-3.84,3.95-7.23-.75-10.82.57-.43,1.13-.86,1.7-1.28,10.18,8.38,21.34,15.86,30.26,25.41,8.71,9.32,7.02,22.97-1.62,34.51-7.88,10.53-19.85,15.22-31.47,9.85-11.5-5.32-21.93-12.93-32.84-19.53.16-.64.33-1.27.49-1.91ZM553.43,161.82c-9.58,13.57-19.31,27.16-28.67,41-.73,1.09.48,4.56,1.83,5.79,4.83,4.43,12.03,4.32,18.34-.24,8.65-6.24,14.4-14.78,17.91-24.74,3.38-9.59.57-16.11-9.42-21.81Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M650.37,362.25c-17.76.09-31.02-13.95-31.31-33.14-.25-16.54,14.4-30.53,32.01-30.57,17.25-.04,30.99,14.89,31.02,33.7.03,16.66-13.98,29.92-31.72,30.01ZM641.86,349.02c9.14-.8,19.91-3.93,29.35-10.61,6.48-4.58,8.37-10.36,6.28-16.75-2.03-6.2-7.26-9.68-15.1-9.66-11.23.03-21.44,3.71-30.87,9.53-7.55,4.66-9.98,10.38-7.96,17.64,1.87,6.74,6.84,9.8,18.3,9.85Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M337.37,147.91c.02,18.06-14.18,31.08-33.91,31.08-16.25,0-29.81-14.61-29.77-32.09.03-17.55,14.46-30.63,33.79-30.63,16.97,0,29.88,13.66,29.9,31.64ZM324.05,159.85c-1.11-12.91-3.58-23.7-9.92-33.14-4.28-6.38-9.87-8.26-16.13-6.47-6.78,1.94-9.86,7.08-10.23,13.49-.77,13.26,2.72,25.57,10.88,36.11,5.76,7.43,16.89,7.63,21.66.45,2.25-3.39,2.86-7.87,3.74-10.44Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M361.38,470.31c-2.36,5.28-4.98,9.74-6.31,14.56-1.28,4.62.92,9,5.68,10.17,8.24,2.03,16.66,3.59,25.09,4.49,7.97.85,15.35,2.4,21.48,7.98,9.27,8.43,11.1,22.81,3.98,32.28-5.88,7.83-9.92,8.23-18.49,1.51,1.64-.92,3.13-1.73,4.6-2.59,4.73-2.78,7.56-6.78,7.42-12.39-.14-5.6-2.87-10.29-7.99-11.9-6.01-1.89-12.53-2.16-18.81-3.24-7.62-1.31-15.66-1.61-22.75-4.33-14.99-5.75-19.36-26.73-8.7-38.84.86-.98,3.05-1.57,4.33-1.24,3.93,1.02,7.72,2.57,10.49,3.54Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M318.07,320.76c-28.43,22.69-79.1,35.15-106.77,19.12,7.68-1.46,14.39-2.59,21.05-4.02,23.53-5.05,46.41-12.1,68.25-22.35,6.42-3.02,11.26-.97,17.47,7.25Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M447.46,320.76c7.61-9.34,11.69-10.14,21.95-5.4,24.06,11.09,49.38,18.14,75.43,22.67,2.47.43,4.93.94,7.61,1.45-19.47,15.48-80.36,4.48-104.99-18.71Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M111.7,299.67c-1.83.31-3.67.6-5.5.94-9.25,1.7-16.63,8.89-18.12,17.65-1.38,8.09,2.01,15.37,11.26,19.82,7.61,3.65,15.9,6.45,24.2,7.95,11.02,2,20.42-6.83,20.63-17.98.02-1.14-.02-2.47-.58-3.39-2.61-4.31-17.02-5.79-21.89-1.33,2.99-8.74,5.59-16.35,8.2-23.96,1.01,8.18,8.17,7.88,13.36,9.52,4.39,1.39,6.38,3.52,5.4,7.87-1.92,8.49-2.96,17.42-6.38,25.29-6.07,13.99-21.72,20.6-35.55,16.51-14.91-4.42-25.11-18.69-22.61-33.95,1.18-7.2,5.44-13.9,8.28-20.83,1.33-3.25,2.62-6.51,4.24-10.54,3.92,1.3,9.48,3.14,15.05,4.98,0,.48,0,.97.01,1.45Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M489.42,173.54c-.58-5.03-.47-9.17-1.72-12.86-.64-1.88-3.6-3.18-5.78-4.14-.38-.16-2.41,2.61-3.13,4.27-1.86,4.29-3.32,8.76-5.13,13.07-1.69,4.01-.75,6.5,3.34,8.39,10.44,4.79,20.01,5.18,27.97-5.34.3.45.6.9.9,1.35-2.44,4.25-4.71,8.62-7.46,12.66-.61.89-3.18,1.26-4.43.78-14-5.35-27.93-10.92-42.13-16.93,5.98.4,7.86-3.42,9.57-7.9,4.58-12.03,9.3-24.01,14.25-35.9,1.92-4.6,2.2-8.31-2.38-11.74,14.58,5.84,29.17,11.68,44.49,17.82-1.95,5.05-3.74,9.66-5.55,14.32-.68-.37-1.27-.55-1.27-.7.76-12.84-4.37-14.96-16.03-19.45-1.31-.5-4.52.99-5.21,2.37-2.41,4.87-3.96,10.16-6.12,15.17-1.79,4.14.29,5.82,3.76,5.47,2.67-.27,5.38-1.8,7.69-3.34,1.65-1.1,2.67-3.15,4.97-4.24-3.44,8.7-6.88,17.41-10.62,26.86Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M208.59,158.11c9.65-5.02,19.09-10.51,29.02-14.91,9.12-4.05,16.82-1.44,20.92,5.74,4.05,7.12,1.62,15.4-6.3,21.42-9.93,7.55-10.03,7.61-3.47,18.85,2.6,4.45,4.16,10.93,12.15,8.54-7.51,4.27-15.02,8.54-22.52,12.81,4.67-4.29,2.34-8.33-.05-12.54-6.46-11.35-12.86-22.74-19.15-34.19-2.34-4.27-5.07-7.09-10.68-3.88l.1-1.84ZM235.64,148.21c-6.75-.08-8.4,2.54-5.21,8.35,2.63,4.79,5.47,9.46,7.94,14.32,1.79,3.52,3.65,3.25,6.37,1.09,4.12-3.29,4.75-7.33,3.23-12.05-2.31-7.16-7.01-11.65-12.33-11.71Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M181.29,179.64c3.98-3.61,7.95-7.21,11.93-10.82-3.44,5.66-.38,9.47,3.03,13.26,6.66,7.39,13.56,14.58,19.83,22.28,8.55,10.51,6.87,20.65-4.43,30.49-10.3,8.96-19.96,8.76-29.31-1.01-6.74-7.04-12.96-14.59-19.75-21.58-2.57-2.64-6.11-4.32-9.57-6.68,4.81-4.42,10.74-9.86,16.67-15.3-3.12,4.81-1.03,8.27,2.28,11.79,7.76,8.23,15.28,16.68,23.03,24.91,5.23,5.55,10.63,6.34,16.17,2.71,5.93-3.88,8.73-11.21,5.98-17.3-1.58-3.49-3.95-6.73-6.47-9.66-5.6-6.5-11.52-12.72-17.3-19.06-3.25-3.56-6.76-6.16-12.1-4.04Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M257.41,350.26c9.27-2.77,18.81-4.87,27.75-8.47,11.02-4.43,21.68-9.81,32.23-15.3,9.43-4.9,16.08-3.98,24.08,4.82-12.74,8.11-25.72,15.39-39.81,20.66-10.85,4.06-21.96,5.5-33.35,3.12-3.85-.81-7.53-2.46-11.28-3.73.12-.37.25-.74.37-1.11Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M508.93,350.49c-8.49,5.64-17.21,5.73-25.9,5.49-11.38-.32-21.71-4.32-31.59-9.7-9.04-4.92-18.13-9.76-27.26-14.68,6.42-8.38,14.66-10.21,22.95-5.52,18.59,10.52,37.91,19.2,59.1,23.06.54.1,1.02.49,2.7,1.34Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M658.24,273.05c-3.79,2.43-8.58,5.5-14.18,9.09,1.91-4.74,4.05-8.28,4.73-12.08,2.61-14.43-9.23-25.44-23.46-22.04-10.28,2.45-18.57,8.26-24.97,16.46-8.3,10.63-3.14,25.52,10.21,30.36,2.3.84,4.67,1.5,7.01,2.24-.11.61-.22,1.22-.33,1.83-2.22-.28-4.56-.19-6.64-.89-12.67-4.26-22.28-18.93-21.83-33.01.4-12.33,9.68-23.7,22.19-27.2,13.57-3.8,28.75,1.5,36.04,13.12,2.55,4.06,3.76,8.94,5.87,13.31,1.36,2.81,3.23,5.37,5.34,8.81Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M366.24,515.01c-.08.92.01,1.37-.16,1.65-7.74,12.56-3.91,21.86,10.6,25.42,5.48,1.34,11.19,1.89,16.51,3.64,14.05,4.63,17.94,20.81,7.84,31.49-4.24,4.48-5.78,4.65-12.16.88,1.69-1.19,3.19-2.19,4.63-3.27,4.35-3.26,5.73-7.66,4.92-12.92-.78-5.09-4.47-7.13-8.84-8.13-3.79-.87-7.84-.76-11.51-1.93-6.06-1.93-12.49-3.63-17.71-7.04-9.76-6.36-11.99-17.61-7.27-28.5,1.75-4.03,3.91-5.51,8.02-3.36,1.67.87,3.51,1.42,5.13,2.05Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M574.34,252.1c-6-5.2-12-10.4-17.31-14.99,3.65-1.91,7.46-3.02,10.09-5.42,9.9-9.07,19.37-18.61,29.12-27.85,3.08-2.92,4.46-5.88,1.19-9.02,6.54,5.83,13.07,11.67,19.61,17.5-5.12-2.8-8.64-1.08-12.36,2.65-9.21,9.23-18.73,18.15-28.19,27.13-3.31,3.14-5.96,6.3-2.13,10Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M372.37,554.53c-7.75,11.69-5.66,18.19,7.02,23.32,3.07,1.24,6.21,2.39,9.09,3.99,9.45,5.25,12.72,17.79,6.79,26.8-1.37,2.08-4.01,4.38-6.27,4.61-4.73.48-5.2,3.23-5.25,6.76-.04,3.1,0,6.19-1.37,9.36-.17-1.79-.35-3.57-.49-5.36-.42-5.1-.09-9.57-7.06-11.98-7.35-2.54-8.93-18.47-3.3-24.41,1.12-1.17,3.64-1.01,6.84-1.79-8.64,13.99-7.53,23.52,2.2,26,0-6.21-.13-12.34.09-18.46.06-1.51,1.26-2.97,1.94-4.46.75,1.49,2.08,2.95,2.14,4.46.25,6.18.1,12.37,1.4,19.85,2.27-2.17,5.32-3.94,6.68-6.57,4.28-8.28.13-17.63-8.79-20.83-3.85-1.38-7.87-2.35-11.63-3.94-8.34-3.53-12.68-10.53-12.39-19.36.32-9.5,3.31-11.52,12.36-7.99Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M383.19,264.5c8.06.04,14.67,6.74,14.74,14.94.08,8.52-6.65,15.22-15.17,15.13-8.09-.09-14.77-6.87-14.79-15-.02-8.48,6.68-15.11,15.22-15.06Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M76.54,475.97c13.54-3.24,26.51-6.34,39.86-9.53,1.81,8.09,3.97,17.73,6.22,27.82-3.28.85-5.82,1.51-8.37,2.17,5.47-4.82,5.01-10.5,3.07-16.24-.43-1.29-2.63-2.99-3.81-2.84-4.38.55-8.68,1.8-13.04,2.79-.04,6.16,4.11,6.85,8.18,8.35-5.13,1.16-10.26,2.32-14.9,3.37,1.83-3,3.66-5.97,5.48-8.95-.52-.64-1.03-1.27-1.55-1.91-4.8,1.1-9.68,1.93-14.33,3.48-1.07.36-2.06,3.27-1.81,4.81,1.03,6.5,3.71,11.99,11.49,13.42-7.67,3.55-9.88,2.58-11.6-4.81-1.8-7.73-3.46-15.5-4.9-21.94Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M424.79,698.58c-1.12-5.02-2.06-9.21-3.09-13.77,2.34-.34,5.13-.74,8.4-1.22,2.13,13.3,4.16,26,6.4,40.01-2.29.06-5.82.04-9.34.3-1.03.07-2.04.85-2.99,1.41-4.83,2.82-9.44,2.07-13.01-2.15-4.07-4.81-5.17-13.81-2.38-19.36,2.72-5.42,7.18-7.07,16-5.21ZM414.75,706.44c1.13,5.4,1.5,9.34,2.89,12.89.68,1.73,3.23,2.73,4.94,4.06,1.49-2.05,3.94-3.98,4.25-6.2.44-3.14-.84-6.48-1.14-9.76-.38-4.13-1.96-7.9-6.42-7.11-2.01.36-3.36,4.45-4.52,6.13Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M488.75,681.53c-2.7-5.33-4.62-9.13-6.77-13.37,2.09-.79,4.92-1.86,8.33-3.14,4.98,12.45,9.81,24.52,14.68,36.69-1.96.38-3.54,5.99-8.45,3.02-.23-.14-1.51,1.41-2.26,2.2-3.56,3.77-7.56,4.44-12.1,1.83-6.03-3.47-9.98-13.29-7.89-19.87,1.95-6.15,6.41-8.42,14.45-7.37ZM495.81,700.3c-2.28-5.35-3.91-10.21-6.44-14.54-.82-1.41-4.18-2.21-6.05-1.8-1.18.26-2.85,3.6-2.42,4.98,1.51,4.95,3.58,9.78,5.99,14.38.66,1.26,3.76,2.57,4.79,2.03,1.77-.93,2.8-3.28,4.13-5.04Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M344.18,233.23c2.72,1.43,7.19,3.5,5.79,9.14-1.42,5.7-6.27,7.15-11.31,7.47-5.2.33-10.44.07-14.92.07v-33.4c4.64,0,9.68-.08,14.72.02,4.37.09,8.87,1.47,9.43,6.06.38,3.07-2.16,6.5-3.71,10.63ZM331.77,233.97c0,3.64-.42,7.32.21,10.81.24,1.34,3.03,3.34,4.42,3.16,4.93-.62,5.83-4.44,5.16-8.66-.83-5.22-4.37-6.2-9.79-5.31ZM331.76,232.72c3.66-1.86,6.66-2.46,7.84-4.26,1.12-1.69,1.15-5.13.12-6.91-.98-1.69-3.94-2.24-6.03-3.29-.64,1.62-1.63,3.2-1.84,4.87-.32,2.43-.08,4.94-.08,9.58Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M336.39,686.61c-.67,2.72-1.45,5.9-2.37,9.62-.64-1.02-1.02-1.43-1.19-1.92-2.89-8.36-6.56-11.65-13.22-11.82-6.29-.16-10.99,3.41-13.8,11.43-1.33,3.8-2.11,7.97-2.18,11.99-.2,11.19,9.23,17.23,19.63,12.92,1.35-.56,2.7-1.13,4.65-.8-.87.76-1.62,1.72-2.61,2.24-8.14,4.26-20.32,1.68-26.81-5.6-6.18-6.93-6.51-18.14-.76-26.02,6.04-8.29,17.19-11.5,26.65-7.55,3.83,1.6,7.56,3.45,12.03,5.51Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M581.83,653.24c-2.31-2.01-5.05-4.4-7.8-6.79.11-.22.21-.45.32-.67,1.48.48,2.95,1.04,4.45,1.42,5,1.27,9.98,1.21,13.41-3.11,1.46-1.84,2.37-5.16,1.75-7.28-.48-1.63-3.66-3.07-5.83-3.42-2.37-.39-4.98.47-7.45.96-2.29.46-4.5,1.31-6.8,1.73-7.62,1.41-12.92-1.4-14.93-7.79-1.89-6,1.2-11.98,7.73-15.24,2.26-1.12,4.38-2.52,7.51-4.34,1.33,1.41,3.42,3.61,5.6,5.91-4.17,0-8.01-.63-11.54.17-4.04.91-7.23,3.91-6.16,8.53,1.12,4.84,5.24,4.49,9.05,3.63,3.42-.77,6.77-1.85,10.2-2.59,8.11-1.73,14.08.83,16.1,6.79,2.24,6.61-1.12,13.87-8.04,17.59-2.6,1.4-5.08,3.01-7.59,4.51Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M551.49,677.74c-3.91,2.44-7.19,4.49-10.93,6.83-3.65-17.93-20.26-19.72-32.4-27.78,3.39-2.2,7.06-4.57,9.46-6.12,7.29,4.45,13.67,8.34,20.44,12.48,2.08-11.42.66-20.28-3.53-23.27,2.04-1.2,3.96-2.34,6.09-3.6,1.04,14.69-4.33,30.48,10.87,41.48Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M163.97,640.06c-1.35-1.44-3.44-3.68-5.17-5.53,4.95-4.55,9.76-8.66,14.15-13.19,1.33-1.37,1.49-3.88,2.18-5.87-2.49-.05-5.69-1.12-7.33.03-5.21,3.67-9.83,8.16-14.68,12.33.31.57.61,1.14.92,1.71-2.18-2.35-4.36-4.7-6.41-6.91,3.72-3.3,6.98-6.51,10.58-9.29,3.56-2.75,7.47-5.04,11.23-7.53-.49-.95-.97-1.91-1.46-2.86,1.67,2.65,3.19,5.41,5.09,7.88.8,1.04,2.37,1.56,3.66,2.15,6.08,2.77,7.47,8.33,2.75,13.11-4.74,4.79-10.01,9.06-15.51,13.97Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M129.27,514.59c-6.09-1.94-10.75.4-12.12,4.58-3,0-5.59-.57-7.8.11-6.24,1.93-12.33,4.36-18.88,6.75-.89-2.5-1.97-5.53-3.09-8.69,11.43-1.16,23.17-6.58,24.24-10.83,2.06.45,4.19,1.49,6.13,1.23,7.66-1.05,12.77,2.54,14.19,10.13.43,2.27,2.07,4.33,2.42,6.6.3,1.96-.46,4.08-.75,6.13-2-.5-4.84-.35-5.85-1.63-2.75-3.49-1.79-10.91,1.49-14.37Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M388.58,393.32c-11.04,1.35-11.09,1.35-11.32-8.27-.15-6.23-.75-12.53-.05-18.67.7-6.16,2.77-12.16,4.98-18.1,10.18,14.23,4.65,29.87,6.38,45.04Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M470.94,715.18c-2.13.49-5.21,2.25-7.13,1.4-3.34-1.48-4.95-.23-7.05,1.71-2.77,2.56-5.95,4.24-9.25.95-3.23-3.22-1.72-6.54.64-9.4,1.98-2.39,4.28-4.54,6.59-6.63,3.22-2.92,2.6-6.32.18-8.73-1.24-1.24-4.4-.56-7.96-.85,1.33,2.74,2.02,3.68,2.24,4.73.28,1.34.75,3.49.12,3.97-1.17.89-3.41,1.52-4.56.95-1.16-.57-2.23-2.77-2.14-4.18.24-4.15,6.72-8.6,12.46-9.03,5.03-.37,8.22,1.86,9.52,6.74.91,3.39,1.65,6.84,2.74,10.17.78,2.4,2.02,4.66,3.6,8.21ZM459.24,704.27c-.71-.12-1.42-.23-2.13-.35-3.14,2.12-4.84,5.08-3.38,8.96.33.87,2.76.94,4.22,1.38.43-3.33.86-6.66,1.29-10Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M361.18,727.57c-3.15,1.89-6.23.34-8.92-3.55-1.63.67-3.24,1.91-4.8,1.85-2.82-.11-5.61-.99-8.41-1.55.83-2.91.83-6.66,2.7-8.53,2.47-2.46,6.4-3.48,9.73-5.07,2.83-1.35,4.62-6.59,2.52-8.69-1.12-1.12-3.38-1.35-5.14-1.41-.4-.01-.88,2.37-1.32,3.64-.51,1.45-.69,3.79-1.62,4.14-1.26.48-3.98-.11-4.48-1.07-.69-1.34-.52-3.85.35-5.15,2.58-3.84,10.02-5.06,15.1-3.06,4.82,1.9,6.04,5.5,5.54,10.33-.59,5.68-.8,11.4-1.24,18.1ZM354.7,713.38c-.66-.36-1.33-.73-1.99-1.09-3.12,1.56-6.64,2.99-6.2,7.53.09.98,2.41,1.74,3.71,2.61,1.49-3.02,2.99-6.03,4.48-9.05Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M232.51,659.18c-1.62,1.9-1.86,2.2-2.12,2.48-1.47,1.59-2.95,3.19-4.43,4.78-1.02-1.24-2.03-2.49-3.05-3.73,1.97-1.52,3.78-4.03,5.94-4.38,5.41-.87,9.83,1.77,13.11,6.07,2.98,3.91.77,7.34-1.26,10.74-1.39,2.34-2.93,4.61-4.11,7.06-1.09,2.27-1.78,4.74-3.19,8.6-1.99-1.2-5.64-2.13-6.4-4.14-1.3-3.47-3.35-3.69-6.17-3.84-3.56-.19-6.86-1.23-6.91-5.51-.05-4.35,3.1-6.09,6.91-6.42,3.09-.27,6.26-.27,9.33.08,4.55.52,6.19-2.61,6.48-5.81.15-1.72-2.45-3.68-4.15-5.98ZM232.54,674.51c-.45-.52-.91-1.04-1.36-1.55-3.57-.05-7.4-.2-8.69,4.25-.28.97,1.91,2.65,2.95,4.01,2.36-2.23,4.73-4.47,7.09-6.7Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M671.32,457.55c9.27.08,15.96,5.88,15.74,13.66-.22,7.74-5.99,12.69-14.57,12.51-8.36-.18-15.1-6-15.18-13.11-.09-7.83,5.59-13.12,14.01-13.05ZM665.01,464.31c-1.55,1.17-3.62,2.72-5.68,4.27,1.91,1.78,3.55,4.46,5.8,5.16,4.57,1.43,9.45,2.17,14.24,2.41,1.78.09,3.66-2.02,5.5-3.13-1.26-1.69-2.19-4.34-3.84-4.9-4.72-1.62-9.72-2.39-16.02-3.82Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M397.34,249.72h-2.84v-32.95c4.03-1.14,7.89-.62,11.09,4.04,3.58,5.22,7.84,9.98,12.84,16.23v-20.39c.62-.04,1.88-.12,2.58-.16v32.57c-.53.22-1.06.44-1.58.66-7-8.51-14.01-17.02-22.09-26.83v26.83Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M144.76,610.89c-5.73,1-9.72-1.44-12.14-6.19-2.5-4.89-1.51-9.83,2.49-13.35,3.12-2.75,6.95-5.05,10.85-6.48,4.6-1.68,10.68,1.59,12.83,5.88,2.21,4.4.57,9.46-5.18,14.84-3.16-3.8-6.32-7.59-9.44-11.33-8.27,6.11-7.95,11.83.58,16.63ZM151.38,600.11c2.24-3.43,3.98-5.77,5.25-8.34.23-.46-1.6-2.8-2.23-2.69-2.97.48-5.84,1.5-9.67,2.6,2.52,3.19,4.02,5.1,6.64,8.43Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M611.8,601.94c-3.15,3.87-6.16,7.56-9.12,11.2,7.26,6.99,13.37,5.42,15.87-3.58,2.8,7.56-1.86,14.95-9.62,15.26-7.23.29-16-7.34-16.67-14.62-.44-4.8.8-9.08,5.4-11.35,5.17-2.55,9.71-.85,14.14,3.09ZM599.77,613.65c2.52-3.38,5.48-5.93,5.02-7.01-.92-2.14-3.6-3.72-5.86-4.95-.54-.29-3.48,2.01-3.32,2.54.79,2.73,2.23,5.27,4.17,9.42Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M381.27,728.51h-8.53v-40.52c8.65-1.12,8.6-1.12,8.55,7.96-.06,10.81-.01,21.62-.01,32.57Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M386.1,249.8h-9.11c-1.7-9.3-3.31-10.48-12.44-9.32-5.8.73-4.62,5.13-4.63,9.23h-4.47c5.08-10.93,10.1-21.73,15.63-33.61,5.23,11.73,9.99,22.42,15.02,33.71ZM368.77,228.58c-.53-.04-1.06-.08-1.59-.12-1.43,3.07-2.86,6.13-4.63,9.92h10.28c-1.5-3.63-2.78-6.72-4.06-9.8Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M117.47,571.11c-9.68-1-14.35-9.26-10.3-16.86,3.56-6.68,13.16-10.47,20.24-8,5.45,1.91,9.14,8.45,7.27,13.61-.58,1.6-2.27,3.04-3.8,3.94-.6.35-3.11-.94-3.15-1.58-.1-1.69.55-3.48,1.14-5.15.64-1.81,1.55-3.53,2.34-5.29-2.35-.23-4.99-1.25-6.97-.5-3,1.13-5.86,3.15-8.2,5.4-5.41,5.2-5.02,9.25,1.43,14.43Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M672.6,511.99c4.24,6.34,1.94,14.26-5.48,16.98-9.23,3.39-20.98-3.66-21.56-12.95-.35-5.6,3.43-11.3,7.93-11.17,1.5.04,2.94,2.1,4.41,3.23-1.25,1.17-2.34,2.61-3.79,3.4-.86.47-2.3-.26-3.44-.11-3.4.45-3.75,2.37-2.02,4.92,3.05,4.51,15.92,6.94,19.89,3.31,2-1.82,2.74-5.02,4.06-7.62Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M192.08,658.94c-5.42.96-9.56-.25-12.67-4.37-3.02-3.99-2.94-8.2-.89-12.64,2.56-5.54,6.8-8.98,12.67-10.23,6.3-1.34,13.47,3.58,13.7,9.52.07,1.69-1.01,3.66-2.12,5.06-.47.58-3.26.39-3.51-.15-.73-1.57-.89-3.48-.94-5.27-.06-1.9.25-3.81.4-5.72-2.26.64-4.73.91-6.71,2.04-1.58.9-2.72,2.74-3.8,4.34-5.17,7.66-4.16,12.6,3.87,17.42Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M382.98,325.71c-7.6-9.92-7.94-16.8-1.74-21.03-2.94-2.38-6-4.85-9.06-7.33.35-.67.71-1.34,1.06-2.01,3.14.4,6.28,1.13,9.42,1.12,3.23-.01,6.45-.75,9.68-1.18.43.52.86,1.04,1.29,1.55-2.59,2.51-5.18,5.03-7.83,7.59,5,6.97,4.61,10.77-2.82,21.28Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M377.95,449.33c0-9.33.08-17.66-.03-25.99-.05-3.95,2.52-3.63,5.11-3.6,2.6.03,5.15-.22,5.03,3.73-.23,7.38.14,14.8-.42,22.15-.51,6.77-5.65,2.79-9.69,3.72Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M607.03,587.73c1.77-1.73,1.92-7.8,8.41-4.89,0-2.86-.38-5.42.15-7.78.3-1.33,1.96-2.94,3.28-3.26,1.04-.25,3.36,1.11,3.57,2.1.28,1.29-.52,3.67-1.59,4.26-4.9,2.7-3.44,5.78-.45,8.31,4.36,3.68,9.19,6.81,14.28,10.5-1.4,1.94-3.39,4.69-4.89,6.76-7.69-5.41-15.01-10.55-22.76-16Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M620.9,567.52c1.77-3.31,3.4-6.35,4.53-8.47,6.17.94,11.81,1.8,17.44,2.66.33-.61.67-1.22,1-1.83-3.78-4-7.56-8.01-11.77-12.48.08-.13.86-1.39,1.76-2.86,6.26,8.3,12.29,16.3,19.33,25.64-11.51-.95-21.58-1.79-32.3-2.67Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M432.28,216.69h8.17v33.01h-8.17v-33.01Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M366,336.27c-1.75,0-3.51.1-5.25-.02-17.52-1.18-32.3,4.42-43.36,18.24-5.1,6.38-10.48,1.47-15.93,1.09,11.13-5.84,22.2-11.77,33.4-17.48,11.94-6.09,16.84-6.43,31.14-1.84Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M400.34,728.26h-9.88v-27.61h7.98c.61,8.92,1.23,17.94,1.9,27.61Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M662.25,549.1c-8.68-3.81-16.83-7.38-25.6-11.23.75-1.71,1.86-4.27,3.16-7.24,8.62,3.6,16.91,7.07,25.79,10.78-1.03,2.37-2.37,5.46-3.34,7.69Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M465.62,356.12c-8.23,1.32-13.93,3.33-19.91-4.38-10.17-13.11-25.31-16.59-41.49-15.58-1.47.09-2.94.17-4.41-.51,9.31-.46,18.29-5.29,28.03.79,10.54,6.58,22.11,11.51,33.23,17.17,1.12.57,2.21,1.21,4.55,2.51Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M98.49,545.69c-1.02-2.38-2.31-5.36-3.29-7.65,8.78-3.74,17.15-7.3,25.63-10.91q3.76,7.15-5.03,10.94c-5.63,2.44-11.23,4.94-17.3,7.62Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M652.43,495.6q1.43-7.73,9.02-5.85c6.53,1.61,13.06,3.19,19.91,4.87-.74,2.83-1.56,5.95-2.18,8.29-9-2.46-17.64-4.82-26.75-7.31Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M145.43,573.87c-8.18,5.39-15.42,10.16-23.11,15.23-1.42-2.19-3.16-4.86-4.48-6.89,8.03-5.3,15.64-10.32,23.49-15.49.98,1.71,2.48,4.32,4.1,7.16Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M213.55,645.66q6.99,4.15,2.1,10.99c-3.79,5.3-7.56,10.6-11.64,16.31-1.88-1.31-4.52-3.16-6.82-4.77,5.46-7.52,10.78-14.84,16.36-22.53Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M378.73,471.61h8.15c0,7.86.15,15.54-.19,23.2-.04.95-2.69,2.53-4.07,2.46-1.34-.07-3.67-1.82-3.72-2.92-.33-7.35-.17-14.71-.17-22.74Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M378.96,539.13c0-7.68-.16-15.04.17-22.37.05-1.1,2.41-2.97,3.62-2.92,1.22.06,3.3,2.04,3.35,3.26.32,7.16.16,14.35.16,22.03h-7.3Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M385.49,565.35c0,2.7.26,5.45-.13,8.09-.16,1.07-1.74,1.94-2.68,2.9-.87-.99-2.45-1.94-2.5-2.98-.27-5.01-.28-10.04-.03-15.05.05-1.07,1.56-2.07,2.39-3.1.99,1.06,2.59,2,2.85,3.22.46,2.22.13,4.61.13,6.93-.01,0-.02,0-.04,0Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M260.42,706.24c-.94-2.67-1.88-5.34-2.82-8.02,2.22-.55,4.97-2.21,6.56-1.44,4.26,2.06,4.12,6.32,1.63,9.46-2.41,3.04-6.05,5.1-9.16,7.6-.36-.46-.73-.92-1.09-1.37,2.3-1.83,4.6-3.67,6.89-5.5-.67-.24-1.34-.48-2.02-.71Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M395.95,338.67c-3.11,2.44-6.22,4.89-9.33,7.33-.57-.17-1.15-.33-1.72-.5v-10.75c3.69.44,7.38.87,11.06,1.31,0,.87,0,1.74,0,2.61Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M379.4,344.88c-2.99-2.31-5.98-4.62-8.97-6.93.04-.7.08-1.41.12-2.11,3.28-.35,6.56-.7,9.85-1.05.26,3.2.52,6.4.78,9.6-.59.16-1.19.33-1.78.49Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M629.06,523.68c2.4,3.01,4.75,4.69,4.68,6.24-.08,1.56-2.41,3.83-4.09,4.18-1.16.24-3.94-2.15-4.09-3.56-.17-1.68,1.7-3.57,3.51-6.86Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M644.41,483.32c1.78,2.7,3.93,4.58,3.61,5.81-.45,1.74-2.73,3.01-4.23,4.49-1.34-1.65-3.47-3.18-3.75-5-.19-1.23,2.23-2.85,4.36-5.3Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M158.2,565.8c-3.47,1.87-5.34,3.69-7,3.52-1.35-.14-3.46-2.76-3.36-4.15.1-1.47,2.46-3.94,3.75-3.89,1.72.07,3.36,2.15,6.62,4.53Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M400.49,690.57c-3.06,2.24-4.83,4.54-6.35,4.39-1.62-.17-3.76-2.48-4.18-4.22-.25-1.02,2.34-3.77,3.8-3.88,1.67-.12,3.5,1.81,6.74,3.71Z"
          />
          <path
            fill={theme.palette.mode === 'light' ? '#0d06f5' : theme.palette.common.white}
            d="M228.71,640.45c-3.02,1.8-4.81,3.69-6.48,3.59-1.37-.08-2.61-2.52-3.91-3.92,1.54-1.4,2.93-3.59,4.67-3.93,1.21-.24,2.92,2.07,5.71,4.26Z"
          />
        </g>
        <g>
          <g>
            <g>
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M1683.13,259.03v120.19h-40.23v-120.19c0-12.7-10.33-23.03-23.03-23.03s-23.03,10.33-23.03,23.03v120.19h-40.22v-120.19c0-12.7-10.33-23.03-23.03-23.03s-23.02,10.33-23.02,23.03v120.19h-40.23v-120.19c0-34.88,28.37-63.25,63.25-63.25,16.68,0,31.83,6.53,43.14,17.12,11.31-10.59,26.46-17.12,43.13-17.12,34.88,0,63.26,28.37,63.26,63.25Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M1741.62,301.22c4.79,23.27,23.53,40.55,45.93,40.55,16.75,0,31.51-9.71,39.82-24.4h41.42c-11.17,36.36-43.35,62.62-81.24,62.62-47.13,0-85.42-40.61-85.42-90.67s38.29-90.67,85.42-90.67c37.89,0,70.13,26.32,81.24,62.68,2.72,8.77,4.19,18.22,4.19,27.99,0,4.05-.27,7.98-.8,11.9h-130.56ZM1827.37,261.33c-8.24-14.69-22.99-24.46-39.82-24.46s-31.58,9.77-39.82,24.46h79.64Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M2053.82,139.55v236.45h-39.49v-6.51c-12.56,7.51-27.05,11.83-42.41,11.83-47.59,0-86.28-41.08-86.28-91.54s38.69-91.47,86.28-91.47c15.36,0,29.85,4.25,42.41,11.77v-70.53h39.49ZM2014.34,289.56c0-.06,0-.13,0-.19,0-1.28-.07-2.68-.19-4.12-.05-.71-.12-1.41-.21-2.11-.05-.44-.1-.88-.17-1.33-.63-4.74-1.79-9.26-3.45-13.48-4.45-11.88-13.11-23.66-28.24-28.25-3.17-1.05-6.48-1.67-9.89-1.87-.09,0-.17-.03-.26-.03-.12,0-.24,0-.37,0-.54-.02-1.08-.09-1.63-.09-.4,0-.79.06-1.2.08-1.08.01-2.15.08-3.21.18-.09.01-.19.02-.28.03-5.91.66-11.46,2.73-16.43,5.92-.08.04-.14.1-.22.14-1.06.69-2.12,1.41-3.12,2.2-.81.62-1.57,1.3-2.34,1.98-.26.24-.54.45-.8.7-10.24,9.47-16.82,24.05-16.82,40.44h0c0,.07,0,.12,0,.17.07,27.39,18.45,49.7,41.65,51.35h.03c.9.07,1.8.16,2.72.16.3,0,.58-.05.87-.05.38,0,.75.05,1.12.03.83-.02,1.61-.16,2.41-.24.07,0,.14-.01.22-.02,8.54-.84,15.56-4.35,21.18-9.39,11.25-9.37,18.61-24.67,18.61-42.01,0-.06,0-.13,0-.22Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M2082.95,379.22v-183.36h39.53v183.36h-39.53Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M1593.63,543.81h41.41c-8.58,40.88-43.08,71.53-84.23,71.53-47.59,0-86.28-41.02-86.28-91.47s38.69-91.54,86.28-91.54c41.15,0,75.65,30.71,84.23,71.59h-41.41c-7.05-18.62-23.61-31.71-42.82-31.71-25.59,0-46.4,23.2-46.4,51.65s20.81,51.58,46.4,51.58c19.21,0,35.77-13.1,42.82-31.64Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M1820.25,521.48c0,50.46-38.71,91.51-86.28,91.51s-86.28-41.05-86.28-91.51,38.7-91.51,86.28-91.51,86.28,41.05,86.28,91.51ZM1780.36,521.47c0-28.46-20.82-51.62-46.39-51.62s-46.39,23.16-46.39,51.62,20.8,51.63,46.39,51.63,46.39-23.16,46.39-51.63Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M1933.16,429.87v40.06h-52.51v143.29h-39.52v-183.35h92.03Z"
              />
              <path
                fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
                d="M1978.19,535.23c4.79,23.27,23.53,40.55,45.93,40.55,16.76,0,31.51-9.71,39.82-24.4h41.42c-11.16,36.36-43.34,62.62-81.24,62.62-47.12,0-85.42-40.61-85.42-90.67s38.29-90.67,85.42-90.67c37.9,0,70.13,26.32,81.24,62.68,2.73,8.77,4.19,18.22,4.19,27.99,0,4.05-.26,7.98-.79,11.9h-130.56ZM2063.94,495.35c-8.24-14.69-22.99-24.46-39.82-24.46s-31.57,9.77-39.82,24.46h79.64Z"
              />
            </g>
            <path
              fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
              d="M2113.61,177.99c7.24,0,13.12-5.87,13.12-13.12v-30.39s-30.66,0-30.66,0c-7.24,0-13.12,5.87-13.12,13.12v30.39s30.66,0,30.66,0Z"
            />
          </g>
          <g>
            <path
              fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
              d="M1352.81,479.25l-62.94-62.94c-8.54-8.54-22.38-8.54-30.91,0l-63.23,63.23c-8.38,8.38-21.96,8.38-30.34,0l-63.23-63.23c-8.54-8.54-22.38-8.54-30.91,0l-62.94,62.94c-8.54,8.54-8.54,22.38,0,30.91l78.4,78.4,54.58,54.58c21.69,21.69,56.85,21.69,78.54,0l54.58-54.58,78.4-78.4c8.54-8.54,8.54-22.38,0-30.91Z"
            />
            <path
              fill={theme.palette.mode === 'light' ? '#0e2459' : theme.palette.common.white}
              d="M1283.65,331.53v-103.27c0-5.74-3.06-11.04-8.03-13.9l-87.04-50.25c-4.97-2.87-11.09-2.87-16.05,0l-87.04,50.25c-4.97,2.87-8.03,8.17-8.03,13.9v103.27c0,4.17,1.62,8.18,4.53,11.17l87.04,89.78c6.31,6.51,16.75,6.51,23.05,0l87.04-89.78c2.9-3,4.53-7,4.53-11.17Z"
            />
          </g>
        </g>
        <rect
          fill={theme.palette.mode === 'light' ? '#0e2459' : '#4057e3'}
          x="840.17"
          y="50.63"
          width="33.39"
          height="703.24"
          rx="16.7"
          ry="16.7"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
