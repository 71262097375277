import PropTypes from 'prop-types';

import Image from '../image';

// ----------------------------------------------------------------------

export default function SingleFilePreview({ imgUrl = '' }) {
  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        borderRadius: 2,
        width: '100%', // Ajusta el ancho al 100% del contenedor
        height: 'auto', // Mantiene la proporción de la imagen
        maxWidth: '300px', // Limita el tamaño máximo de la imagen
      }}
    />
  );
}

SingleFilePreview.propTypes = {
  imgUrl: PropTypes.string,
};
