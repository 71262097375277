import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const CalendarAppointmentsPage = lazy(() => import('src/pages/medicore/doctor/appointments-calendar-view'));
const ConsultingRoomPage = lazy(() => import('src/pages/medicore/doctor/consulting-rooms-view'));
const ActiveConsultingPatientPage = lazy(() => import('src/pages/medicore/doctor/active-patient-consulting-view'));


// ----------------------------------------------------------------------

export const doctorRoutes = [
  {
    path: 'medicore',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'consultingRooms', element: <ConsultingRoomPage /> },
      { path: 'appointmentCalendar', element: <CalendarAppointmentsPage /> },
      { path: 'consultingPatient', element: <ActiveConsultingPatientPage /> },
    ],
  },
];
