import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const EmergencyEditor = lazy(() => import('src/pages/medicore/emergency/editor'));
// const PageFour = lazy(() => import('src/pages/medicore/four'));
// const PageFive = lazy(() => import('src/pages/medicore/five'));
// const PageSix = lazy(() => import('src/pages/medicore/six'));

// ----------------------------------------------------------------------

export const emergencyRoutes = [
  {
    path: 'medicore/emergency',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <EmergencyEditor />, index: true },
      { path: 'editor', element: <EmergencyEditor /> },
    ],
  },
];
