import axios from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const configureAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      if (
        config.method === 'post' &&
        config.url.includes('https://id.who.int/icd/release/11/2024-01/mms/search')
      ) {
        console.log('Solicitud POST interceptada:', {
          url: config.url,
          method: config.method,
          data: config.data,
          headers: config.headers,
        });
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

// ----------------------------------------------------------------------

export const endpoints = {
  doctorAppointments: {
    get: '/api/DoctorAppointmentInfo/DoctorAppointments',
    post: '/api/DoctorAppointmentInfo',
    put: '/api/DoctorAppointmentInfo',
    delete: '/api/DoctorAppointmentInfo',
  },
  auth: {
    profile: '/api/User/profile',
    login: '/api/User/login',
    register: '/api/auth/register',
  },
};
