import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

// ----------------------------------------------------------------------

export default function LoadingScreenOverlay({ sx, text = '', ...other }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2147483647, // Valor máximo de zIndex para asegurar que esté por encima de todo
        backdropFilter: 'blur(8px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      {text && (
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            color: 'inherit',
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
      )}
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360, height: 5 }} />
    </Box>
  );
}

LoadingScreenOverlay.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
};
