/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Link, Divider, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fCurrency } from 'src/utils/format-number';
import { fDate, fTime } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function JournalTableRow({ row, selected, onViewRow, onSelectRow, onDeleteRow }) {
  const confirm = useBoolean();
  const popover = usePopover();

  const totalDebit = row.reduce((acc, cur) => (cur.isDebit ? acc + cur.amount : acc), 0);
  const totalCredit = row.reduce((acc, cur) => (!cur.isDebit ? acc + cur.amount : acc), 0);

  console.log(row);
  const getDateValue = (date) => (
    <>
      <Typography>{fDate(date)}</Typography>
      <Typography>{fTime(date)}</Typography>
    </>
  );
  const renderPrimary = (
    <>
      <TableRow key={row[0].journalEntryId} hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell
          sx={{
            borderRightWidth: 0.5,
            borderRightColor: 'rgba(139, 138, 138, .2)',
            borderRightStyle: 'solid',
          }}
        >
          <Link sx={{ cursor: 'pointer' }}>{row[0].transactionId}</Link>
        </TableCell>

        {['inventario', 'amount-debit', 'amount-credit', 'date'].map((field, fieldIndex) => (
          <TableCell
            key={field}
            sx={{
              p: 0,
              borderRightWidth: 0.5,
              borderRightColor: 'rgba(139, 138, 138, .2)',
              borderRightStyle: 'solid',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',

                height: '100%',
              }}
            >
              {row.map((entry, index) => (
                <React.Fragment key={`${field}-${index}`}>
                  <Box
                    sx={{
                      height: 80,
                      display: 'flex',
                      justifyContent:
                        field === 'amount-debit'
                          ? 'center'
                          : field === 'amount-credit'
                          ? 'center'
                          : 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      pt={index === 0 ? 0 : 2}
                      pb={row.length > index + 1 ? 0 : 2}
                      pl={field === 'amount-debit' ? 0 : field === 'amount-credit' ? 0 : 2}
                    >
                      {field === 'inventario'
                        ? entry.accountName.accountName
                        : field === 'amount-debit'
                        ? entry.isDebit
                          ? fCurrency(entry.amount)
                          : ' '
                        : field === 'amount-credit'
                        ? !entry.isDebit
                          ? fCurrency(entry.amount)
                          : ' '
                        : getDateValue(entry.createDate)}
                    </Typography>
                  </Box>
                  {row.length > index + 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </TableCell>
        ))}

        <TableCell align="right">
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: 'success' }}>
        <TableCell
          colSpan={3}
          sx={{
            borderRightWidth: 0.5,
            borderRightColor: 'rgba(139, 138, 138, .2)',
            borderRightStyle: 'solid',
            textAlign: 'center',
          }}
        >
          <Typography>
            <strong>RESUMEN TOTAL</strong>
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderRightWidth: 0.5,
            borderRightColor: 'rgba(139, 138, 138, .2)',
            borderRightStyle: 'solid',
            textAlign: 'center',
          }}
        >
          <Typography>
            <strong>{fCurrency(totalDebit)}</strong>
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderRightWidth: 0.5,
            borderRightColor: 'rgba(139, 138, 138, .2)',
            borderRightStyle: 'solid',
            textAlign: 'center',
          }}
        >
          <Typography>
            <strong>{fCurrency(totalCredit)}</strong>
          </Typography>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </>
  );

  return (
    <>
      {renderPrimary}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error">
            Delete
          </Button>
        }
      />
    </>
  );
}

JournalTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.array,
  selected: PropTypes.bool,
};
