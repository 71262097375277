import axios from 'src/utils/axios';

// ACCOUNTS
export const createAccount = async (payload) => {
  const response = await axios.post('/api/AccountCatalog', payload);
  return response;
};

export const getAccounts = async () => {
  const response = await axios.get('api/AccountCatalog');
  return response;
};

// BANKS
export const createBank = async (payload) => {
  const response = await axios.post('/api/Bank/Bank', payload);
  return response;
};

export const getBanks = async () => {
  const response = await axios.get('api/Bank/Bank');
  return response;
};

// BANK ACCOUNTS
export const createBankAccount = async (payload) => {
  const response = await axios.post('/api/BankAccount/BankAccount', payload);
  return response;
};

export const getBankAccounts = async () => {
  const response = await axios.get('api/BankAccount/Bank');
  return response;
};

export const getBankAccountsById = async (id) => {
  const response = await axios.get(`api/BankAccount/Bank/${id}`);
  return response;
};

// CHECKS
export const createCheck = async (payload) => {
  const response = await axios.post('/api/Checks/checks', payload);
  return response;
};

export const getChecks = async () => {
  const response = await axios.get('api/Checks/checks');
  return response;
};

export const getChecksById = async (id) => {
  const response = await axios.get(`api/Checks/checks/${id}`);
  return response;
};

// JOURNAL ENTRY
export const getAllJournalEntries = async () => {
  const response = await axios.get('/api/JournalEntry');
  return response;
};

export const getJournalEntriesbyTransaction = async () => {
  const response = await axios.get('/api/JournalEntry/byTransactions');
  return response;
};

// INVOICE ACCOUNTING
export const getInvoiceAccounting = async () => {
  const response = await axios.get('/api/InvoiceAccounting/InvoiceAccounting');
  return response;
};
