import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { usePathname } from 'src/routes/hooks';

import { _socials } from 'src/_mock';

import Iconify from 'src/components/iconify';
import LogoGrumebaMedicore from 'src/components/logo/logo-app';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'GRUMEBA',
    children: [
      { name: 'Servicios', href: '#services' },
      { name: 'Ubicación', href: '#location' },
      { name: 'Aseguradoras Permitidas', href: '#providers-section' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Términos y condiciones', href: '#' },
      { name: 'Política de privacidad', href: '#' },
    ],
  },
  {
    headline: 'Contacto',
    children: [{ name: 'ayuda@grumeba.com.do', href: '#' }],
  },
];

// ----------------------------------------------------------------------

const handleScrollToSection = (event, sectionId) => {
  event.preventDefault();
  const element = document.querySelector(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export default function Footer() {
  const pathname = usePathname();

  const homePage = pathname === '/ff';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <LogoGrumebaMedicore sx={{ width: 500, height: 200 }} />

        <Typography variant="caption" component="div">
          © All rights reserved
          <br /> made by
          <Link href="https://nexuscore.com.do/"> nexus core crh </Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <LogoGrumebaMedicore sx={{ width: 280, height: 110, mb: 2 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={5}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 400,
                mx: { xs: 'auto', md: 'unset' },
                textAlign: { xs: 'center', md: 'justify' },
              }}
            >
              Ofrecemos una amplia gama de servicios de salud, incluyendo consultas médicas en
              diversas especialidades, diagnóstico y tratamiento de enfermedades, y atención de
              emergencias. Nuestro compromiso es brindar atención médica de calidad a la comunidad,
              con un equipo de profesionales altamente capacitados y tecnología moderna.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  href={social.path}
                  target="_blank"
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component="a"
                      href={link.href}
                      color="inherit"
                      variant="body2"
                      onClick={(e) => handleScrollToSection(e, link.href)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © {new Date().getFullYear()}. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );

  return homePage ? simpleFooter : mainFooter;
}
