import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { deleteCategory } from 'src/services/inventory/inventory-service-calls';
import { getJournalEntriesbyTransaction } from 'src/services/finance/finance-service-calls';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

// import CategoriesTableToolbar from '../categories-table-toolbar';
// import CategoriesTableFiltersResult from '../categories-table-filters-result';
// import CategoriesNewEditForm from '../categories-new-edit-form';
// import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import JournalTableRow from '../journal-table-row';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'Todos' }];

const TABLE_HEAD = [
  { id: 'transactionId', label: 'Procedencia' },
  { id: 'accountType', label: 'Tipo de cuenta' },
  { id: 'debit', label: 'Debito' },
  { id: 'credit', label: 'Credito' },
  { id: 'createdDate', label: 'Fecha de creacion' },
  { id: '' },
];

const defaultFilters = {
  name: '',
  type: '',
  status: 'all',
};

// ----------------------------------------------------------------------

export default function JournalListView() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [loading] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);

  const newEditCategory = useBoolean();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getJournalEntriesbyTransaction();
      console.log(data.data);
      setTableData(data.data);
    };
    fetchData();
  }, []);

  // const refreshData = useCallback(async () => {
  //   setLoading(true);
  //   const response = await getCategories();
  //   if (response.status === 200) {
  //     setCurrentCategories(
  //       response?.data?.map((category) => ({
  //         id: category.id,
  //         productCategoryName: category.productCategoryName,
  //         createdDate: category.createdDate,
  //       }))
  //     );
  //     setCurrentEntries(mockedData);
  //     setTableData(mockedData);
  //     setLoading(false);
  //   }
  // },[ setCurrentEntries, setCurrentEntries]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table?.dense ? 52 : 72;

  const canReset = !!filters?.name || filters?.status !== 'all' || filters?.type !== '';

  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id === id);
      console.log(JSON.stringify(deleteRow));
      deleteCategory(deleteRow.id);
      // refreshData();

      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const rowToDelete = tableData.filter((row) => table.selected.includes(row.id));
    deleteCategory(rowToDelete.id);
    // refreshData();

    table.onUpdatePageDeleteRows({
      totalRows: tableData?.length,
      totalRowsInPage: dataInPage?.length,
      totalRowsFiltered: dataFiltered?.length,
    });
  }, [dataFiltered?.length, dataInPage?.length, table, tableData]);

  // const handleResetFilters = useCallback(() => {
  //   setFilters(defaultFilters);
  // }, []);

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.order.details(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Asientos contables"
          links={[
            {
              name: 'Medicore',
              href: paths.medicore.root,
            },
            {
              name: 'Contabilidad',
              href: paths.medicore.finance.journal.list,
            },
            { name: 'Asientos' },
          ]}
          action={
            <Button
              variant="contained"
              onClick={newEditCategory.onTrue}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              Agregar Asiento
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={
                      (tab.value === 1 && 'success') ||
                      (tab.value === 2 && 'warning') ||
                      (tab.value === 0 && 'error') ||
                      'default'
                    }
                  >
                    {tab.value === 'all' && tableData?.length}
                    {tab.value === 2 &&
                      tableData?.filter((space) => space.roomStatus === 2)?.length}

                    {tab.value === 1 &&
                      tableData?.filter((space) => space.roomStatus === 1)?.length}
                    {tab.value === 0 &&
                      tableData?.filter((space) => space.roomStatus === 0)?.length}
                  </Label>
                }
              />
            ))}
          </Tabs>

          {/* <CategoriesTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            canReset={canReset}
            onResetFilters={handleResetFilters}
          /> */}

          {
            canReset
            // && (
            //   <CategoriesTableFiltersResult
            //     filters={filters}
            //     onFilters={handleFilters}
            //     //
            //     onResetFilters={handleResetFilters}
            //     //
            //     results={dataFiltered?.length}
            //     sx={{ p: 2.5, pt: 0 }}
            //   />
            // )
          }

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table?.dense}
              numSelected={table?.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row[0].journalEntryId)
                    )
                  }
                />

                <TableBody>
                  {tableData
                    ?.slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row, index) => (
                      <JournalTableRow
                        key={row[0].journalEntryId}
                        row={row}
                        selected={table.selected.includes(row[0].journalEntryId)}
                        onSelectRow={() => table.onSelectRow(row[0].journalEntryId)}
                        onDeleteRow={handleDeleteRow}
                        onViewRow={() => handleViewRow(row[0].journalEntryId)}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered?.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
      {/* <Dialog
        open={newEditCategory.value}
        onClose={(event, reason) => newEditCategory.onCloseNotBackdrop(event, reason)}
        fullWidth
      >

        <DialogTitle>{categoryEdit ? 'Editar Categoria' : 'Nueva categoria'}</DialogTitle>
        <DialogContent>
          <CategoriesNewEditForm
            currentCategory={currentCategories}
            newEditCategory={newEditCategory}
          />
        </DialogContent>
      </Dialog> */}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delet <strong> {table.selected?.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { status, name, type } = filters;

  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  if (name) {
    inputData = inputData?.filter(
      (space) => space?.roomNumber?.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData?.filter((space) => space.roomStatus === status);
  }

  if (type !== '') {
    inputData = inputData?.filter((space) => space.roomType === type);
  }

  return inputData;
}
