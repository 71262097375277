// src/components/ChequeTable.js
// import { Add as AddIcon } from '@mui/icons-material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

import {
  Box,
  Table,
  Paper,
  Modal,
  Button,
  Select,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  InputLabel,
  FormControl,
  TableContainer,
} from '@mui/material';

import { getBanks, getChecks, getAccounts } from 'src/services/finance/finance-service-calls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CheckListView() {
  const [cheques, setCheques] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newCheque, setNewCheque] = useState({
    checkNumber: '',
    bankAccountId: '',
    amount: 0,
    description: '',
    templateId: '',
    recievedName: '',
    checkDate: new Date().toISOString(),
    createUser: '',
    accountCatalog: '',
    procedentType: 0,
    otherAffectedAccount: '',
  });
  const [accounts, setAccounts] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [affectedAccounts, setAffectedAccounts] = useState([]);

  useEffect(() => {
    fetchCheques();
    fetchAccounts();
    fetchCatalogs();
  }, []);

  const fetchCheques = async () => {
    const response = await getChecks();
    setCheques(response.data);
  };

  const fetchAccounts = async () => {
    const response = await getBanks();
    setAccounts(response.data);
  };

  const fetchCatalogs = async () => {
    const response = await getAccounts();
    setCatalogs(response.data);
    setAffectedAccounts(response.data);
  };

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCheque({ ...newCheque, [name]: value });
  };

  const handleSubmit = async () => {
    // Reemplaza con tu servicio para crear un cheque
    await axios.post('/api/cheques', newCheque);
    fetchCheques();
    handleClose();
  };

  return (
    <>
      <Button variant="contained" color="primary" size="small" onClick={handleOpen}>
        Crear Cheque
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número de Cheque</TableCell>
              <TableCell>Cuenta Bancaria</TableCell>
              <TableCell>Monto</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Recibido Por</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cheques.map((cheque) => (
              <TableRow key={cheque.checkNumber}>
                <TableCell>{cheque.checkNumber}</TableCell>
                <TableCell>{cheque.bankAccountId}</TableCell>
                <TableCell>{cheque.amount}</TableCell>
                <TableCell>{cheque.description}</TableCell>
                <TableCell>{cheque.recievedName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style}>
          <h2>Crear Cheque</h2>
          <TextField
            label="Número de Cheque"
            name="checkNumber"
            value={newCheque.checkNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Cuenta Bancaria</InputLabel>
            <Select name="bankAccountId" value={newCheque.bankAccountId} onChange={handleChange}>
              {accounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Monto"
            name="amount"
            type="number"
            value={newCheque.amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Descripción"
            name="description"
            value={newCheque.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Recibido Por"
            name="recievedName"
            value={newCheque.recievedName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Catálogo de Cuentas</InputLabel>
            <Select name="accountCatalog" value={newCheque.accountCatalog} onChange={handleChange}>
              {catalogs.map((catalog) => (
                <MenuItem key={catalog.id} value={catalog.id}>
                  {catalog.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Otra Cuenta Afectada</InputLabel>
            <Select
              name="otherAffectedAccount"
              value={newCheque.otherAffectedAccount}
              onChange={handleChange}
            >
              {affectedAccounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Box>
      </Modal>
    </>
  );
}
