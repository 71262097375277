import axios from 'src/utils/axios';

// WAREHOUSES
export const getWarehouses = async () => {
  const response = await axios.get('api/warehouse');
  return response;
};

export const getWarehouseById = async (id) => {
  const response = await axios.get(`api/warehouse/${id}`);
  return response;
};

export const createWarehouse = async (payload) => {
  const response = await axios.post('api/warehouse/', payload);
  return response;
};

export const updateWarehouse = async (id, payload) => {
  const response = await axios.put(`api/warehouse/${id}`, payload);
  return response;
};

export const deleteWarehouse = async (id) => {
  const response = await axios.delete(`/api/warehouse/${id}`);
  return response;
};
// DISPATCH
export const getDispatch = async () => {
  const response = await axios.get('/dispatch');
  return response;
};

export const getDispatchById = async (id) => {
  const response = await axios.get(`/dispatch/${id}`);
  return response;
};

export const createDispatch = async (payload) => {
  const response = await axios.post('/dispatch', payload);
  return response;
};

// INVENTORY
export const getInventory = async () => {
  const response = await axios.get('/api/Inventory/Inventory');
  return response;
};

export const getInventoryById = async (id) => {
  const response = await axios.get(`/inventory/${id}`);
  return response;
};

export const createInventory = async (payload) => {
  const response = await axios.post('/api/Inventory/Inventory', payload);
  return response;
};

export const assignProductsToInventory = async (payload) => {
  const response = await axios.post('/api/Inventory/AsingedInventory', payload);
  return response;
};

export const closeInventory = async (data) => {
  const response = await axios.put('/api/Inventory/closeFirstInventory', data);
  return response;
};

// PRODUCTS
export const getProducts = async () => {
  const response = await axios.get('/api/Product/Product');
  return response;
};

export const getProductById = async (id) => {
  const response = await axios.get(`/api/Product/Product/${id}`);
  return response;
};

export const createProduct = async (payload) => {
  const response = await axios.post('/api/Product/Product', payload);
  return response;
};

export const updateProduct = async (id, payload) => {
  const response = await axios.put(`/api/Product/Product/${id}`, payload);
  return response;
};

export const deleteProduct = async (id) => {
  const response = await axios.delete(`/api/Product/Product/${id}`);
  return response;
};

// PROVIDERS
export const getProviders = async () => {
  const response = await axios.get('/api/Provider/provider');
  return response;
};

export const getProvidersById = async (id) => {
  const response = await axios.get(`/api/Provider/Provider/${id}`);
  return response;
};

export const assignProvider = async (payload) => {
  const response = await axios.post('/api/Provider/AsignedProvider', payload);
  return response;
};

export const createProvider = async (payload) => {
  const response = await axios.post('/api/Provider/Provider', payload);
  return response;
};

export const updateProvider = async (id, payload) => {
  const response = await axios.put(`/api/Provider/Provider/${id}`, payload);
  return response;
};

export const deleteProvider = async (id) => {
  const response = await axios.delete(`/api/Provider/Provider/${id}`);
  return response;
};

export const deleteProductInProvider = async (id) => {
  const response = await axios.delete(`/api/Provider/ProductProvider/${id}`);
  return response;
};

export const getProductsAssigned = async () => {
  const response = await axios.get('/api/Provider/products');
  return response;
};

// CATEGORIES
export const getCategories = async () => {
  const response = await axios.get('api/product/productCategory');
  return response;
};

export const getCategoryById = async (id) => {
  const response = await axios.get(`/categories/${id}`);
  return response;
};

export const createCategory = async (payload) => {
  const response = await axios.post('/api/Product/Category', payload);
  return response;
};

export const deleteCategory = async (id) => {
  const response = await axios.delete(`/api/Product/productCategory/${id}`);
  return response;
};

// ORDERS
export const getOrders = async () => {
  const response = await axios.get('/orders');
  return response;
};

export const getOrderById = async (id) => {
  const response = await axios.get(`/orders/${id}`);
  return response;
};

export const createOrder = async (payload) => {
  const response = await axios.post('/orders', payload);
  return response;
};
