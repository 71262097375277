import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const ListRoomPage = lazy(() => import('src/pages/medicore/rooms/list'));
const InventoryStatisticsPage = lazy(() => import('src/pages/medicore/inventory/statistics'));
const InventoryListViewPage = lazy(() => import('src/pages/medicore/inventory/list'));
const WarehouseViewPage = lazy(() => import('src/pages/medicore/inventory/warehouse'));
const MedicalOrderListPage = lazy(() => import('src/pages/medicore/inventory/listMedicalOrder'));
const PurchaseOrderListPage = lazy(() => import('src/pages/medicore/inventory/purchaseOrder-list'));
const PurchaseOrderNewPage = lazy(() => import('src/pages/medicore/inventory/purchaseOrder-new'));
const ProductsListPage = lazy(() => import('src/pages/medicore/inventory/list-product'));
const ProviderListPage = lazy(() => import('src/pages/medicore/inventory/list-provider'));
const CategoriesListPage = lazy(() => import('src/pages/medicore/inventory/list-category'));
const DispatchStatisticsPage = lazy(() =>
  import('src/pages/medicore/inventory/dispatch-statistics')
);
const DispatchProductPage = lazy(() => import('src/pages/medicore/inventory/dispatch-product'));
const RequisitionRequestListPage = lazy(() =>
  import('src/pages/medicore/inventory/requisitionRequest-list')
);
const RequisitionRequestNewPage = lazy(() =>
  import('src/pages/medicore/inventory/requisitionRequest-new')
);

// ----------------------------------------------------------------------

export const medicoreInventoryRoutes = [
  {
    path: 'medicore/inventory',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'statistics', element: <InventoryStatisticsPage />, index: true },
      {
        path: 'list',
        element: <InventoryListViewPage />,
      },
      {
        path: 'warehouse',
        element: <WarehouseViewPage />,
      },
      {
        path: 'listMedicalOrder',
        element: <MedicalOrderListPage />,
      },
      {
        path: 'purchaseOrder',
        children: [
          { path: 'listPurchaseOrder', element: <PurchaseOrderListPage /> },
          { path: 'newPurchaseOrder', element: <PurchaseOrderNewPage /> },
        ],
      },
      {
        path: 'requisition',
        children: [
          { path: 'newRequisition', element: <RequisitionRequestNewPage /> },
          { path: 'listRequisition', element: <RequisitionRequestListPage /> },
        ],
      },
      {
        path: 'listProduct',
        element: <ProductsListPage />,
      },
      {
        path: 'listProvider',
        element: <ProviderListPage />,
      },
      {
        path: 'listCategory',
        element: <CategoriesListPage />,
      },
      {
        path: 'dispatch',
        children: [
          { path: 'statistics', element: <DispatchStatisticsPage /> },
          { path: 'dispatchProduct', element: <DispatchProductPage /> },
          { path: 'dispatchWarehouse', element: <ListRoomPage /> },
        ],
      },
    ],
  },
];
