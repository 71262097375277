import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { RouterLink } from 'src/routes/components';

import { PATH_AFTER_LOGIN } from 'src/config-global';

import LogoMedicore from 'src/components/logo/logo-medicore-button';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
  return (
    <Button
      component={RouterLink}
      href={PATH_AFTER_LOGIN}
      variant="contained"
      sx={{
        backgroundColor: '#0d06f5',
        '&:hover': {
          backgroundColor: '#00baeb',
        },
        ...sx,
      }}
    >
      <LogoMedicore
        sx={{
          width: 130,
          height: 40,
        }}
        disabledLink
      />
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
