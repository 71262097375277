import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { EmployeesGridView } from 'src/sections/human-resources/employees/view';

const DepartmentsListView = lazy(() =>
  import('src/sections/human-resources/departments/view/departments-list')
);
const JobPositionsListView = lazy(() =>
  import('src/sections/human-resources/jobPositions/view/job-positions-list')
);
const NewEmployeeRequestListView = lazy(() =>
  import('src/pages/medicore/human-resources/new-employee-request-view')
);

const NewJobPositionsView = lazy(() =>
  import('src/pages/medicore/human-resources/new-job-position-grid-view')
);

const EditJobPositionsView = lazy(() =>
  import('src/pages/medicore/human-resources/edit-job-position-view')
);

const DetailsJobPositionsView = lazy(() =>
  import('src/pages/medicore/human-resources/details-job-position-view')
);
// ----------------------------------------------------------------------

export const medicoreHumanResourcesRoutes = [
  {
    path: 'medicore',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'humanResources/employees',
        children: [{ path: 'list', element: <EmployeesGridView /> }],
      },
      {
        path: 'humanResources/departments',
        element: <DepartmentsListView />,
      },
      {
        path: 'jobPositions',
        children: [
          { element: <JobPositionsListView />, index: true },
          { path: 'new', element: <NewJobPositionsView /> },
          { path: 'edit/:id', element: <EditJobPositionsView /> },
          { path: 'details/:id', element: <DetailsJobPositionsView /> },
        ],
      },
      {
        path: 'employeeRequest/request',
        element: <NewEmployeeRequestListView />,
      },
    ],
  },
];
