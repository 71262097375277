import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function RHFAutocomplete({
  name,
  label,
  placeholder,
  helperText,
  options,
  multiple = false,
  customFunction,
  ...other
}) {
  const { control, setValue } = useFormContext();

  const findOptionByValue = (value) => {
    if (multiple) {
      return value ? value.map((v) => options.find((option) => option.value === v) || v) : [];
    }
    return options.find((option) => option.value === value) || null;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple={multiple}
          options={options}
          getOptionLabel={(option) => option.label || option}
          isOptionEqualToValue={(option, value) => option.value === value || option === value}
          value={findOptionByValue(field.value)}
          onChange={(event, newValue) => {
            const value = multiple
              ? newValue.map((item) => item.value || item)
              : newValue?.value || newValue;
            setValue(name, value, { shouldValidate: true });
            if (customFunction) {
              customFunction();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
            />
          )}
          {...other}
        />
      )}
    />
  );
}

RHFAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  customFunction: PropTypes.func,
};
