import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  emergency: icon('ic_emergency'),
  patient: icon('ic_patient'),
  rooms: icon('ic_rooms'),
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  medicalServiceCatalog: icon('ic_medicalservicecatalog'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'dashboards',
        items: [
          {
            title: 'Inicio',
            path: paths.medicore.root,
            icon: ICONS.dashboard,
          },
        ],
      },

      // PATIENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Gestión de pacientes',
        items: [
          { title: 'Admisiones', icon: ICONS.patient, path: paths.medicore.admissions },
          {
            title: 'Pacientes',
            path: paths.patient.root,
            icon: ICONS.patient,
            children: [
              {
                title: 'Registrar',
                path: paths.patient.new,
              },
              {
                title: 'Registros existentes',
                path: paths.patient.list,
              },
            ],
          },
          {
            title: 'Emergencias',
            path: paths.medicore.emergency.editor,
            icon: ICONS.emergency,
          },
          {
            title: 'Internamiento',
            path: paths.medicore.hospitalized,
            icon: ICONS.patient,
          },
          {
            title: 'UCI',
            path: paths.medicore.intensiveCareUnit,
            icon: ICONS.patient,
          },
          {
            title: 'facturación',
            path: paths.medicore.patientInvoice.root,
            icon: ICONS.invoice,
            children: [
              { title: 'facturas', path: paths.medicore.patientInvoice.list },
              { title: 'crear Factura', path: paths.medicore.patientInvoice.new },
            ],
          },
          {
            title: 'Enfermería',
            path: paths.medicore.nurse.root,
            icon: ICONS.patient,
            children: [
              { title: 'Turnos', path: paths.medicore.nurse.shifts },
              { title: 'Solicitar productos', path: paths.medicore.nurse.requestproducts },
            ],
          },
          {
            title: 'Servicios médicos',
            path: paths.medicore.laboratories,
            icon: ICONS.rooms,
          },
          {
            title: 'Defunciones',
            path: paths.medicore.deaths,
            icon: ICONS.patient,
          },
          {
            title: 'Solicitudes de Admisión',
            path: paths.medicore.admissionRequests,
            icon: ICONS.emergency,
          },
          {
            title: 'Ejemplo',
            path: paths.medicore.ejemplo,
            icon: ICONS.patient,
          },
          /* {
            title: 'rawing',
            path: paths.patient.rawing,
            icon: ICONS.dashboard,
          }, */
        ],
      },
      // APPOINTMENTS
      // ----------------------------------------------------------------------
      {
        subheader: 'Consultas Médicas',
        items: [
          {
            title: 'Calendario de Citas',
            path: paths.medicore.appointmentCalendar,
            icon: ICONS.calendar,
          },
          {
            title: 'Consultas Activas',
            path: paths.medicore.consultingPatient,
            icon: ICONS.user,
          },
          {
            title: 'Consultorios',
            path: paths.medicore.consultingRooms,
            icon: ICONS.rooms,
          },
        ],
      },


      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Administración',
        items: [
          {
            title: 'habitaciones/espacios',
            path: paths.medicore.rooms.root,
            icon: ICONS.rooms,
            children: [
              { title: 'Crear espacio', path: paths.medicore.rooms.new },
              { title: 'Espacios existentes', path: paths.medicore.rooms.list },
            ],
          },
          {
            title: 'Catálogo de servicios',
            path: paths.medicore.medicalServiceCatalog.root,
            icon: ICONS.medicalServiceCatalog,
          },
        ],
      },
      {
        subheader: 'Gestión de inventario',
        items: [
          {
            title: 'Analíticas de Inventario',
            path: paths.medicore.inventory.statistics,
            icon: ICONS.dashboard,
          },
          {
            title: 'Inventario',
            path: paths.medicore.inventory.list,
            icon: ICONS.dashboard,
          },
          {
            title: 'Almacenes',
            path: paths.medicore.inventory.warehouse,
            icon: ICONS.dashboard,
          },
          {
            title: 'Órdenes médicas',
            path: paths.medicore.inventory.listMedicalOrder,
            icon: ICONS.dashboard,
          },
          {
            title: 'Órdenes de compra',
            path: paths.medicore.inventory.purchaseOrder.root,
            icon: ICONS.order,
            children: [
              {
                title: 'Órdenes existentes',
                path: paths.medicore.inventory.purchaseOrder.listPurchaseOrder,
              },
              {
                title: 'Crear orden',
                path: paths.medicore.inventory.purchaseOrder.newPurchaseOrder,
              },
            ],
          },
          {
            title: 'Requisiciones',
            path: paths.medicore.inventory.requisition.root,
            icon: ICONS.order,
            children: [
              {
                title: 'Requisiciones existentes',
                path: paths.medicore.inventory.requisition.listRequisition,
              },
              {
                title: 'Solicitar requisición',
                path: paths.medicore.inventory.requisition.newRequisition,
              },
            ],
          },
          {
            title: 'Productos',
            path: paths.medicore.inventory.listProduct,
            icon: ICONS.dashboard,
          },
          {
            title: 'Proveedores',
            path: paths.medicore.inventory.listProvider,
            icon: ICONS.dashboard,
          },
          {
            title: 'Categorías',
            path: paths.medicore.inventory.listCategory,
            icon: ICONS.dashboard,
          },
          {
            title: 'Despachos',
            path: paths.medicore.inventory.dispatch.root,
            icon: ICONS.order,
            children: [
              { title: 'Estadísticas', path: paths.medicore.inventory.dispatch.statistics },
              { title: 'Productos', path: paths.medicore.inventory.dispatch.dispatchProduct },
              { title: 'Requisiciones', path: paths.medicore.inventory.dispatch.dispatchWarehouse },
            ],
          },
        ],
      },
      {
        subheader: 'Contabilidad',
        items: [
          {
            title: 'Cuentas',
            path: paths.medicore.finance.accounts.root,
            icon: ICONS.order,
            children: [{ title: 'Catálogo', path: paths.medicore.finance.accounts.list }],
          },
          {
            title: 'Asientos contables',
            path: paths.medicore.finance.accounts.root,
            icon: ICONS.order,
            children: [{ title: 'Asientos', path: paths.medicore.finance.journal.list }],
          },
          {
            title: 'Cheques',
            path: paths.medicore.finance.checks.list,
            icon: ICONS.order,
          },
          {
            title: 'Punto de venta',
            path: paths.medicore.finance.pos,
            icon: ICONS.order,
          },
          {
            title: 'Entrada contable',
            path: paths.medicore.finance.entry.root,
            icon: ICONS.order,
            children: [
              { title: 'Crear', path: paths.medicore.finance.entry.root },
              { title: 'Lista', path: paths.medicore.finance.entry.list },
            ],
          },
          {
            title: 'Activos fijos',
            path: paths.medicore.finance.fixedAssets.root,
            icon: ICONS.order,
          },
          {
            title: 'Préstamos',
            path: paths.medicore.finance.loans.root,
            icon: ICONS.order,
          },
          {
            title: 'Facturas',
            path: paths.medicore.finance.invoices.root,
            icon: ICONS.order,
            children: [
              { title: 'Crear', path: paths.medicore.finance.invoices.root },
              { title: 'Lista', path: paths.medicore.finance.invoices.list },
            ],
          },
          {
            title: 'Nómina',
            path: paths.medicore.finance.payroll.root,
            icon: ICONS.order,
          },
          {
            title: 'Caja Chica',
            path: paths.medicore.finance.pettyCash.root,
            icon: ICONS.order,
          },
          {
            title: 'Reportes',
            path: paths.medicore.finance.report.root,
            icon: ICONS.order,
          },
        ],
      },
      {
        subheader: 'Seguros',
        items: [
          {
            title: 'Proveedores de seguros',
            path: paths.medicore.insurance.providers.root,
            icon: ICONS.rooms,
            children: [
              { title: 'Lista de proveedores', path: paths.medicore.insurance.providers.list },
            ],
          },
          {
            title: 'Auditoría de seguros',
            path: paths.medicore.insurance.audit.root,
            icon: ICONS.rooms,
            children: [
              {
                title: 'Lista de autorizaciones',
                path: paths.medicore.insurance.audit.list,
              },
            ],
          },
        ],
      },
      {
        subheader: 'Recursos Humanos',
        items: [
          {
            title: 'Gestion de personal',
            path: paths.medicore.humanResources.root,
            icon: ICONS.order,
            children: [
              { title: 'Empleados', path: paths.medicore.humanResources.employees.list },
              {
                title: 'departamentos',
                path: paths.medicore.humanResources.departments.root,
              },
            ],
          },
          {
            title: 'Vacantes',
            path: paths.medicore.humanResources.jobPositions.root,
            icon: ICONS.job,
            children: [
              { title: 'Lista', path: paths.medicore.humanResources.jobPositions.root },
              { title: 'Nueva Vacante', path: paths.medicore.humanResources.jobPositions.new },
            ],
          },
          {
            title: 'Solicitud de Empleados',
            path: paths.medicore.employeeRequest.request,
            icon: ICONS.order,
          },
        ],
      },
    ],
    []
  );
  return data;
}
