import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import PatientPortalLayout from 'src/layouts/patient-portal/patient-portal-layout';
import PatientPortalCompactLayout from 'src/layouts/patient-portal/patient-compact-layout';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// PAGES
const PatientPortalLoginPage = lazy(() => import('src/pages/medicore/patient/portal/login-form'));
const PatientPortalResultsRetrievePage = lazy(() =>
  import('src/pages/medicore/patient/portal/results-retrieve-form')
);
const PatientPortalAppointmentPage = lazy(() =>
  import('src/pages/medicore/patient/portal/appointment')
);

export const patientPortalRoutes = [
  {
    path: 'patient/patient-portal',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <PatientPortalLayout image="/assets/background/portal-patients.jpg">
            <PatientPortalLoginPage />
          </PatientPortalLayout>
        ),
      },
      {
        path: 'results',
        element: (
          <PatientPortalCompactLayout>
            <PatientPortalResultsRetrievePage />
          </PatientPortalCompactLayout>
        ),
      },
      {
        path: 'appointments',
        element: (
          <PatientPortalCompactLayout>
            <PatientPortalAppointmentPage />
          </PatientPortalCompactLayout>
        ),
      },
    ],
  },
];
