import axios from '../../utils/axios';

export const getEmployees = async () => {
  const response = await axios.get(`/api/EmployeeInfo`);
  return response;
};

export const getEmployeeById = async (id) => {
  const response = await axios.get(`/api/EmployeeInfo/${id}`);
  return response;
};

export const createEmployee = async (payload) => {
  const response = await axios.post('/api/EmployeeInfo', payload);
  return response;
};

export const updateEmployee = async (id, payload) => {
  const response = await axios.put(`/api/EmployeeInfo/${id}`, payload);
  return response;
};

export const deleteEmployee = async (id) => {
  const response = await axios.delete(`/api/EmployeeInfo/${id}`);
  return response;
};
