import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

export default function LogoMedicoreGrumeba({ disabledLink = false, lightMode, sx, ...other }) {
  const logo = (
    <Box
      component="div"
      sx={{
        width: 210,
        height: 60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1086.97 1080"
      >
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M845.05,545.65c-28.25,67.04-55.71,132.21-83.16,197.37-1.01.05-2.02.11-3.03.16-27.78-64.42-55.55-128.83-83.33-193.25l-2.49.42c-.23,2.91-.67,5.82-.67,8.74-.03,48.12-.05,96.23.04,144.35.05,25.26,3.32,29.17,27.77,34.47,1.31.28,2.44,1.39,3.35,3.96h-72.57c-.13-.89-.26-1.78-.38-2.68,2.73-.57,5.44-1.23,8.18-1.71,14-2.45,21.81-10.15,21.89-24.38.33-54.44.26-108.89-.08-163.33-.08-12.91-6.39-18.6-19.38-19.95-3.51-.36-7.02-.64-10.53-.96-.06-1.03-.13-2.06-.19-3.09,2.65-.47,5.3-1.34,7.96-1.35,24.06-.09,48.12.21,72.17-.18,6.96-.11,9.92,2.72,12.46,8.78,17.34,41.42,35.03,82.68,52.64,123.99,1.33,3.12,2.89,6.13,5.38,11.37,2.45-4.95,4.19-8,5.53-11.23,17.14-41.51,34.38-82.98,51.14-124.64,2.59-6.43,5.92-8.37,12.6-8.26,23.42.37,46.85.08,70.27.1,2.92,0,5.84.35,8.76.54l.34,2.97c-2.55.62-5.08,1.63-7.66,1.8-17.15,1.12-22.72,5.78-22.83,22.39-.36,54.76-.33,109.52-.06,164.28.07,14.81,6.2,20.33,20.94,21.4,3.13.23,6.23.83,9.35,1.27-.07.92-.14,1.84-.21,2.75h-113.24c-.06-.92-.12-1.83-.19-2.75,3.19-.4,6.37-.94,9.57-1.16,14.56-.98,21.25-6.8,21.34-21.19.34-55.39.16-110.79.14-166.18,0-.8-.58-1.61-1.83-4.82Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M354.01,652.87h103.92c.18.89.36,1.79.53,2.68-2.58.79-5.12,1.83-7.76,2.32-12.08,2.23-17.06,7.46-17.34,19.83-.34,14.87-.28,29.75-.03,44.63.09,5.13-1.43,7.86-6.49,9.86-36.71,14.53-74.64,19.97-113.62,13.73-53.8-8.62-92.84-52.4-94.57-104.44-1.87-56.11,33.8-104.66,86.73-117.8,30.37-7.54,60.21-6.02,89.31,6.06,3.5,1.45,7.07,2.76,10.69,3.88,9.42,2.91,15.02.57,19.81-7.84,1.36-2.4,3.17-4.54,4.78-6.8.77.31,1.54.62,2.31.93v75.45c-.78.33-1.55.66-2.33.99-1.55-2.51-3.53-4.85-4.58-7.55-14-36.12-44.76-58.27-80.14-57.52-28.02.6-50.53,16.76-59.41,43.53-12.71,38.34-13.37,77.17-2.17,115.9,10.08,34.86,44.9,53.59,80.95,44.43,3.07-.78,6.06-1.91,9.17-2.45,5.59-.98,8.02-3.84,7.89-9.73-.33-14.87.03-29.76-.33-44.63-.33-13.64-6.03-19.49-19.41-21.07-2.83-.34-5.63-.9-8.44-1.35.18-1,.36-2.01.54-3.01Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M579.43,593.41c5.14-2.42,10.37-4.51,15.25-7.23,17.28-9.64,18.39-28.32.92-37.97-10.72-5.92-23.59-8.94-35.86-10.79-16.84-2.53-34.07-2.33-51.06-4-24.18-2.38-45.63-11.48-61.25-30.5-21.3-25.93-12.09-67.27,18.83-81.48,13.19-6.06,28.33-8.64,42.89-10.7,11.61-1.64,20.13,6.5,27.07,15.02,4.21,5.17-.44,9.2-4.38,10.08-9.31,2.08-19.28,4.78-28.4,3.38-17.15-2.62-28.46-.16-32.7,12.55-6.82,20.44-5.81,30.96,10.14,41.73,16,10.8,34.56,10.9,52.76,13.19,20.61,2.59,41.65,4.43,61.41,10.28,25.01,7.4,39,27.63,39.63,50.16.51,18.11-8.72,30.49-23.29,39.61-1.75,1.09-5.02,1.41-6.8.51-8.4-4.24-16.52-9.03-25.15-13.85Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M655.55,366.48c-10.53,16.87-25.95,10.84-40.36,11.72,2.67,1.58,5.34,3.17,9,5.34-23.34,15.32-47.95,18.98-74.25,15.92,2.69-15.33,4.84-17.47,19.38-19.85,15.8-2.59,26.35-14.26,27.14-30.78.42-8.84-.3-17.72-.04-26.57.43-14.9,7.82-23.1,22.63-21.44,12.95,1.45,26.27,4.88,38.04,10.4,20.83,9.76,40.62,21.74,60.8,32.87,1.55.85,2.71,2.4,5.2,4.68-25.08,21.45-53.11,13.42-80.78,12.11,4.37,1.85,8.75,3.7,13.24,5.6Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M543.07,399.79c-27.11,2.23-51.88-.76-75.29-16.45,3.76-2.02,6.36-3.41,8.96-4.8-.12-.7-.23-1.41-.35-2.11-7.22.75-14.69,2.99-21.59,1.81-6.47-1.1-12.33-5.81-18.45-8.93.27-.94.53-1.88.8-2.83,3.83-1.73,7.65-3.46,11.48-5.19-26.57-1.99-55.2,11-79.58-13.45,5.59-3.81,10.23-7.33,15.2-10.29,24.47-14.58,49.07-28.94,77.13-35.7,4.54-1.09,9.4-1.44,14.08-1.24,13.21.57,20.14,7.96,20.35,21.19.15,9.79-.47,19.65.41,29.37,1.4,15.52,10.38,26.77,25.17,27.65,13.87.83,18.21,8.6,21.7,20.97Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M507.18,541.69c-4.83,3.22-8.62,5.38-12,8.07-13.15,10.46-12.62,26.1,1.17,35.38,14.35,9.65,31.04,9.63,47.35,11.56,13.4,1.59,27.21,2.62,39.87,6.81,21.42,7.09,33.68,28.5,31.2,50.92-.51,4.6-2.02,9.34-4.12,13.48-6.82,13.43-12.99,15.12-26.22,7.89-3.27-1.78-6.48-3.66-10.34-5.84,1.82-1.16,2.78-1.99,3.9-2.46,8.69-3.6,15.9-8.26,14.72-19.36-1.22-11.51-6.98-20.11-18.2-22.95-9.38-2.37-19.29-3.28-29-3.58-18.81-.58-37.27-2.41-54.73-9.96-21.06-9.12-32.98-25.55-31.91-44.85.33-5.89,1.87-11.89,3.87-17.48,5.43-15.13,10.1-17.28,25.13-12.89,5.95,1.74,11.97,3.27,19.3,5.26Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M549.87,131.4c-8.31-.85-16.62-1.71-24.93-2.56,8.85-3.04,10.99-9.41,11.74-17.72,1.86-20.78,4.41-41.49,6.85-62.22.88-7.49-.48-13.33-8.8-16.77,11.19,1.43,22.46,2.45,33.5,4.64,2.15.42,3.98,5.19,4.96,8.27,5.36,16.87,10.39,33.85,15.59,50.78.43,1.42,1.26,2.71,2.77,5.87,3.57-6.01,6.49-10.62,9.1-15.4,6.98-12.77,13.99-25.53,20.65-38.46,2.37-4.6,5.2-6.4,10.48-5.6,9.34,1.42,18.77,2.19,25.78,2.97-2.82,4.06-7.98,8.21-8.63,12.98-3.16,23.17-5.57,46.48-7.1,69.82-.3,4.51,4.49,9.36,6.61,13.43-12.09-1.23-26.45-2.68-40.8-4.14,8.63-1.94,11.13-7.41,11.77-15.47,1.5-18.92,3.79-37.78,5.71-56.66.34-3.35.42-6.72-1.61-10.85-14.51,26.18-29.01,52.35-44.8,80.85-9.88-31.23-19.03-60.11-28.17-89-.68.08-1.36.15-2.04.23-1.59,14.29-3.22,28.57-4.76,42.87-.85,7.86-1.4,15.76-2.34,23.61-.91,7.63.44,13.94,8.48,18.54Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M402.05,372.17c-25.19,7.98-50.05,17.29-75.66,23.53-21.63,5.27-44.04,8.27-66.28,9.76-11.55.78-23.77-2.9-35.09-6.46-6.33-1.98-11.44-7.82-17.1-11.93.35-1.06.69-2.11,1.04-3.17,5.93-1.04,11.91-1.89,17.79-3.16,42.23-9.12,84.47-18.18,126.61-27.69,5.56-1.25,8.43-1.02,12.77,3.56,4.64,4.89,11.94,7.73,18.56,10.12,5.2,1.87,11.15,1.69,16.76,2.42.2,1,.39,2,.59,3.01Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M883.55,384.52c-14.21,14.48-29.22,21.09-46.61,21.33-31.01.43-60.8-7.05-90.23-15.75-19.02-5.62-37.66-12.51-56.24-20.42,7.71-1.59,15.52-2.81,23.09-4.92,3.81-1.06,8.83-2.84,10.44-5.84,5.03-9.34,11.31-6.3,18.99-4.58,43.43,9.71,87,18.76,130.52,28.04,2.61.56,5.22,1.11,10.04,2.14Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M584.97,505.17c9.64-4.12,18.71-7.01,26.78-11.64,18.02-10.34,20.31-35.08,5.21-49.36-7.09-6.71-15.02-8.74-24.39-5.37-11.83,4.25-22.94,2.21-33.46-4.37-5.09-3.18-4.84-6.11-1.04-10.58,12.49-14.71,28.18-14.03,45.12-11.14,34.55,5.89,55.53,29.53,53.52,62.34-1.28,20.86-13.94,34.59-30.98,44.79-2.17,1.3-5.94,1.74-8.18.76-10.83-4.73-21.4-10.07-32.57-15.44Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M200.73,320.64c-7.28,11-14.56,22-21.84,33,.66-8.13-3.57-12.36-9.73-16.3-17.56-11.24-34.78-23.01-52.1-34.62-6.56-4.4-13.06-8.11-19.93,1.33-.39-.89-.78-1.78-1.17-2.67,12.31-17.04,24.08-34.51,37.14-50.96,8.06-10.15,19.71-12.02,29.81-7.32,10.78,5.02,14.66,13.04,13.32,27.41-.14,1.52-.02,3.06-.02,6.45,18.67-.99,36.95-1.97,54.49-2.9-3.74,14.52-13.55,22.34-30.41,22.62-10.43.18-20.88.34-31.27,1.12-3.01.22-5.86,2.55-8.78,3.91,1.74,2.27,3.1,5.07,5.31,6.72,5.3,3.97,11.11,7.27,16.52,11.11,5.95,4.22,11.73,6.6,18.66,1.11ZM119.36,277.86c10.48,6.99,21.09,14.18,31.88,21.09,1.15.74,3.82.42,5.02-.46,10.08-7.35,10.67-23.42,1.35-31.65-15.08-13.33-28.79-9.74-38.25,11.02Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M742.71,178.65c8.51,5.34,13.28,1.26,17.72-5.19,12.38-17.98,24.91-35.86,37.73-53.52,4.51-6.22,6.4-11.71-1.21-17.53.92-.69,1.83-1.39,2.75-2.08,16.5,13.58,34.57,25.69,49.04,41.17,14.11,15.1,11.37,37.22-2.62,55.93-12.77,17.07-32.17,24.66-50.99,15.96-18.63-8.61-35.54-20.95-53.21-31.65.27-1.03.53-2.06.8-3.09ZM822.43,124.88c-15.52,21.99-31.29,44.01-46.45,66.43-1.19,1.76.79,7.39,2.97,9.39,7.83,7.18,19.49,7,29.72-.38,14.02-10.1,23.34-23.95,29.02-40.09,5.47-15.55.92-26.11-15.26-35.34Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M979.5,449.64c-28.78.15-50.27-22.6-50.73-53.7-.4-26.8,23.34-49.47,51.86-49.54,27.95-.07,50.21,24.12,50.27,54.61.05,26.99-22.66,48.47-51.4,48.62ZM965.71,428.21c14.82-1.3,32.26-6.36,47.56-17.19,10.5-7.43,13.57-16.79,10.18-27.14-3.29-10.05-11.76-15.68-24.46-15.65-18.2.05-34.74,6-50.03,15.44-12.24,7.55-16.17,16.83-12.9,28.58,3.03,10.91,11.09,15.88,29.66,15.96Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M472.33,102.34c.04,29.26-22.98,50.36-54.95,50.37-26.33,0-48.3-23.67-48.24-52,.05-28.44,23.43-49.63,54.75-49.64,27.49,0,48.41,22.13,48.45,51.27ZM450.74,121.68c-1.8-20.92-5.81-38.41-16.08-53.7-6.94-10.33-16-13.38-26.13-10.48-10.98,3.14-15.97,11.47-16.57,21.86-1.25,21.49,4.4,41.44,17.64,58.52,9.33,12.04,27.36,12.37,35.09.73,3.65-5.49,4.64-12.76,6.05-16.92Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M511.24,624.75c-3.82,8.56-8.08,15.78-10.23,23.59-2.07,7.49,1.49,14.59,9.2,16.48,13.35,3.28,26.99,5.81,40.66,7.27,12.91,1.38,24.87,3.9,34.8,12.93,15.02,13.66,17.98,36.95,6.45,52.3-9.53,12.69-16.08,13.34-29.97,2.45,2.65-1.49,5.08-2.8,7.45-4.19,7.67-4.5,12.25-10.98,12.02-20.08-.22-9.07-4.65-16.67-12.95-19.28-9.75-3.07-20.31-3.5-30.49-5.24-12.35-2.12-25.37-2.6-36.86-7.01-24.28-9.32-31.37-43.31-14.1-62.93,1.39-1.58,4.94-2.55,7.02-2.01,6.37,1.65,12.51,4.17,17,5.74Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M441.05,382.42c-46.06,36.77-128.18,56.96-173.01,30.99,12.44-2.36,23.33-4.2,34.11-6.52,38.13-8.18,75.21-19.6,110.59-36.22,10.4-4.89,18.24-1.56,28.31,11.75Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M650.71,382.42c12.34-15.14,18.93-16.42,35.57-8.76,38.99,17.97,80.01,29.39,122.22,36.73,4.01.7,7.99,1.52,12.33,2.35-31.55,25.08-130.21,7.26-170.13-30.32Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M106.66,348.24c-2.97.51-5.95.98-8.91,1.52-14.99,2.76-26.94,14.41-29.36,28.61-2.24,13.11,3.25,24.91,18.25,32.11,12.33,5.92,25.77,10.45,39.21,12.89,17.86,3.25,33.09-11.07,33.43-29.14.03-1.84-.03-4.01-.93-5.49-4.24-6.98-27.59-9.38-35.47-2.16,4.85-14.16,9.07-26.49,13.28-38.83,1.64,13.25,13.24,12.76,21.65,15.42,7.11,2.25,10.35,5.7,8.75,12.76-3.11,13.76-4.8,28.22-10.34,40.98-9.84,22.67-35.2,33.39-57.6,26.75-24.16-7.16-40.69-30.29-36.63-55,1.92-11.67,8.81-22.52,13.42-33.76,2.16-5.26,4.24-10.55,6.87-17.09,6.35,2.1,15.37,5.08,24.38,8.06,0,.78.01,1.57.02,2.35Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M718.7,143.87c-.94-8.15-.76-14.87-2.79-20.84-1.03-3.05-5.84-5.16-9.37-6.7-.61-.27-3.91,4.24-5.07,6.92-3.02,6.95-5.38,14.19-8.32,21.18-2.74,6.51-1.22,10.54,5.42,13.59,16.92,7.77,32.42,8.39,45.33-8.66.49.73.98,1.46,1.47,2.18-3.95,6.89-7.63,13.97-12.08,20.51-.98,1.45-5.15,2.04-7.17,1.26-22.69-8.68-45.25-17.7-68.26-27.44,9.69.64,12.74-5.54,15.5-12.8,7.43-19.49,15.07-38.91,23.09-58.16,3.1-7.45,3.57-13.46-3.86-19.02,23.63,9.47,47.26,18.93,72.09,28.88-3.17,8.18-6.06,15.65-8.99,23.21-1.11-.6-2.07-.89-2.05-1.14,1.23-20.8-7.09-24.24-25.98-31.52-2.12-.82-7.33,1.6-8.44,3.84-3.91,7.89-6.41,16.47-9.92,24.58-2.9,6.7.46,9.44,6.09,8.86,4.32-.45,8.72-2.91,12.46-5.4,2.68-1.79,4.33-5.11,8.05-6.86-5.57,14.1-11.15,28.21-17.2,43.53Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M263.66,118.86c15.64-8.13,30.94-17.03,47.02-24.17,14.78-6.56,27.26-2.33,33.89,9.31,6.57,11.53,2.62,24.95-10.21,34.71-16.09,12.24-16.25,12.33-5.62,30.54,4.21,7.21,6.74,17.72,19.69,13.83-12.17,6.92-24.33,13.84-36.5,20.76,7.58-6.94,3.79-13.5-.09-20.32-10.47-18.4-20.84-36.85-31.03-55.4-3.8-6.92-8.21-11.48-17.31-6.29l.16-2.98ZM307.49,102.82c-10.93-.13-13.61,4.12-8.45,13.53,4.26,7.76,8.87,15.33,12.87,23.21,2.9,5.7,5.92,5.27,10.32,1.76,6.68-5.33,7.69-11.87,5.23-19.52-3.74-11.61-11.36-18.88-19.97-18.98Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M219.41,153.75c6.44-5.85,12.89-11.69,19.33-17.54-5.58,9.17-.62,15.35,4.91,21.49,10.79,11.97,21.97,23.62,32.13,36.11,13.86,17.03,11.13,33.46-7.19,49.4-16.69,14.52-32.34,14.19-47.49-1.63-10.93-11.41-20.99-23.65-32.01-34.96-4.16-4.27-9.9-7-15.51-10.83,7.8-7.16,17.41-15.98,27.02-24.8-5.06,7.79-1.68,13.4,3.7,19.1,12.57,13.33,24.76,27.03,37.32,40.37,8.47,9,17.23,10.27,26.2,4.39,9.6-6.29,14.15-18.17,9.69-28.03-2.55-5.65-6.4-10.91-10.48-15.65-9.07-10.53-18.66-20.61-28.03-30.88-5.27-5.78-10.95-9.99-19.61-6.54Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M342.76,430.22c15.03-4.48,30.48-7.9,44.97-13.72,17.85-7.17,35.13-15.9,52.23-24.79,15.28-7.94,26.06-6.45,39.03,7.81-20.64,13.14-41.68,24.93-64.5,33.47-17.58,6.58-35.59,8.92-54.04,5.06-6.24-1.3-12.2-3.99-18.28-6.04.2-.6.4-1.2.6-1.8Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M750.32,430.59c-13.76,9.14-27.89,9.29-41.97,8.9-18.44-.52-35.17-7.01-51.18-15.72-14.65-7.98-29.38-15.82-44.18-23.78,10.41-13.58,23.75-16.55,37.19-8.94,30.12,17.05,61.42,31.11,95.77,37.37.87.16,1.65.8,4.38,2.18Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M992.27,305.1c-6.14,3.94-13.91,8.91-22.97,14.72,3.09-7.68,6.56-13.41,7.67-19.58,4.23-23.38-14.96-41.22-38.01-35.72-16.66,3.97-30.09,13.39-40.47,26.67-13.45,17.22-5.08,41.35,16.54,49.19,3.73,1.36,7.57,2.43,11.36,3.64-.18.99-.35,1.97-.53,2.96-3.6-.45-7.39-.31-10.76-1.44-20.52-6.9-36.11-30.68-35.37-53.49.65-19.98,15.68-38.4,35.96-44.08,21.99-6.15,46.58,2.43,58.4,21.27,4.13,6.58,6.1,14.49,9.51,21.57,2.2,4.55,5.23,8.7,8.66,14.28Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M519.1,697.18c-.13,1.49.02,2.22-.26,2.68-12.54,20.36-6.33,35.42,17.18,41.18,8.87,2.17,18.13,3.06,26.75,5.9,22.76,7.51,29.07,33.73,12.7,51.02-6.87,7.26-9.37,7.53-19.71,1.43,2.75-1.93,5.17-3.55,7.5-5.29,7.06-5.28,9.29-12.41,7.98-20.93-1.27-8.25-7.24-11.55-14.32-13.17-6.14-1.41-12.7-1.23-18.65-3.12-9.82-3.12-20.23-5.89-28.7-11.41-15.81-10.31-19.43-28.54-11.78-46.18,2.83-6.53,6.33-8.94,13-5.44,2.7,1.41,5.68,2.29,8.3,3.33Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M856.31,271.15c-9.73-8.42-19.45-16.84-28.06-24.3,5.92-3.09,12.09-4.89,16.34-8.79,16.05-14.69,31.39-30.15,47.18-45.13,4.98-4.73,7.23-9.53,1.94-14.61,10.59,9.45,21.18,18.9,31.77,28.36-8.29-4.54-14-1.74-20.03,4.3-14.93,14.95-30.36,29.41-45.68,43.96-5.36,5.09-9.65,10.21-3.46,16.21Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M529.04,761.21c-12.55,18.95-9.16,29.47,11.37,37.79,4.97,2.01,10.06,3.87,14.72,6.46,15.31,8.51,20.61,28.82,11.01,43.43-2.22,3.38-6.5,7.1-10.16,7.47-7.66.78-8.43,5.23-8.51,10.95-.07,5.02-.01,10.04-2.22,15.17-.27-2.89-.56-5.78-.8-8.68-.68-8.26-.14-15.51-11.44-19.41-11.91-4.12-14.48-29.93-5.34-39.55,1.81-1.9,5.9-1.64,11.08-2.9-14.01,22.66-12.2,38.12,3.57,42.13,0-10.07-.21-20,.15-29.92.09-2.44,2.05-4.82,3.14-7.22,1.21,2.41,3.37,4.78,3.46,7.23.4,10.01.17,20.04,2.26,32.16,3.69-3.51,8.62-6.38,10.83-10.65,6.94-13.42.21-28.57-14.25-33.75-6.24-2.24-12.75-3.81-18.84-6.38-13.51-5.71-20.55-17.07-20.07-31.37.51-15.39,5.37-18.66,20.03-12.95Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M546.56,291.26c13.06.07,23.77,10.92,23.89,24.21.12,13.8-10.77,24.67-24.58,24.51-13.1-.15-23.94-11.14-23.97-24.31-.03-13.74,10.82-24.48,24.66-24.41Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M49.68,633.92c21.93-5.24,42.95-10.27,64.59-15.44,2.93,13.11,6.43,28.73,10.08,45.08-5.32,1.38-9.44,2.45-13.56,3.52,8.86-7.81,8.12-17.01,4.97-26.31-.7-2.09-4.26-4.84-6.17-4.6-7.1.9-14.07,2.91-21.13,4.52-.06,9.99,6.66,11.1,13.26,13.53-8.32,1.88-16.63,3.76-24.14,5.46,2.97-4.86,5.92-9.68,8.88-14.51l-2.51-3.09c-7.78,1.78-15.69,3.13-23.22,5.65-1.73.58-3.33,5.31-2.94,7.8,1.68,10.54,6.02,19.42,18.61,21.75-12.42,5.76-16.02,4.18-18.8-7.8-2.91-12.53-5.61-25.11-7.94-35.55Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M613.98,994.62c-1.82-8.13-3.34-14.93-5-22.32,3.79-.55,8.31-1.2,13.61-1.97,3.45,21.56,6.74,42.13,10.37,64.83-3.71.09-9.43.07-15.13.48-1.67.12-3.3,1.38-4.85,2.28-7.83,4.56-15.3,3.35-21.08-3.49-6.59-7.79-8.38-22.37-3.85-31.38,4.41-8.78,11.63-11.45,25.92-8.45ZM597.7,1007.37c1.83,8.75,2.43,15.13,4.68,20.88,1.09,2.8,5.24,4.42,8,6.57,2.42-3.33,6.39-6.44,6.89-10.04.71-5.09-1.36-10.51-1.84-15.82-.61-6.69-3.18-12.81-10.41-11.52-3.26.58-5.45,7.21-7.32,9.93Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M717.62,967c-4.37-8.63-7.49-14.79-10.97-21.66,3.39-1.28,7.96-3.01,13.5-5.09,8.07,20.18,15.9,39.74,23.78,59.45-3.18.61-5.73,9.7-13.69,4.89-.37-.22-2.44,2.28-3.66,3.57-5.77,6.11-12.24,7.2-19.6,2.97-9.77-5.62-16.16-21.53-12.78-32.19,3.16-9.96,10.38-13.65,23.42-11.95ZM729.06,997.42c-3.69-8.67-6.33-16.54-10.43-23.56-1.33-2.28-6.77-3.58-9.8-2.92-1.91.41-4.61,5.83-3.93,8.07,2.45,8.02,5.81,15.85,9.71,23.29,1.07,2.05,6.1,4.16,7.76,3.29,2.87-1.51,4.53-5.32,6.7-8.17Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M483.35,240.58c4.41,2.31,11.65,5.67,9.38,14.81-2.3,9.23-10.16,11.59-18.32,12.11-8.43.54-16.92.12-24.18.12v-54.13c7.52,0,15.69-.13,23.85.04,7.09.15,14.38,2.39,15.29,9.83.61,4.98-3.49,10.53-6.02,17.23ZM463.26,241.78c0,5.9-.68,11.87.35,17.51.4,2.18,4.9,5.41,7.15,5.12,7.99-1,9.45-7.2,8.36-14.04-1.34-8.45-7.08-10.04-15.86-8.6ZM463.24,239.75c5.93-3.01,10.79-3.98,12.71-6.9,1.81-2.74,1.86-8.31.19-11.2-1.58-2.74-6.39-3.63-9.78-5.33-1.04,2.63-2.63,5.18-2.99,7.9-.51,3.94-.13,8-.13,15.53Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M470.73,975.24c-1.09,4.4-2.36,9.56-3.85,15.6-1.03-1.65-1.65-2.32-1.92-3.12-4.68-13.54-10.62-18.87-21.42-19.15-10.2-.26-17.81,5.53-22.36,18.52-2.15,6.15-3.42,12.91-3.54,19.42-.33,18.13,14.95,27.93,31.82,20.94,2.19-.91,4.38-1.83,7.53-1.3-1.4,1.23-2.63,2.78-4.24,3.62-13.19,6.91-32.92,2.73-43.45-9.07-10.01-11.22-10.54-29.39-1.24-42.16,9.79-13.43,27.85-18.63,43.18-12.23,6.21,2.59,12.24,5.59,19.49,8.93Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M868.45,921.16c-3.74-3.25-8.19-7.13-12.64-11.01.17-.36.35-.73.52-1.09,2.4.78,4.77,1.69,7.22,2.3,8.11,2.05,16.18,1.96,21.73-5.04,2.37-2.98,3.85-8.36,2.83-11.79-.78-2.64-5.93-4.97-9.45-5.55-3.84-.63-8.07.76-12.07,1.56-3.71.75-7.3,2.12-11.01,2.8-12.35,2.28-20.93-2.28-24.2-12.63-3.07-9.72,1.94-19.42,12.53-24.69,3.66-1.82,7.1-4.08,12.17-7.03,2.16,2.28,5.54,5.84,9.08,9.58-6.76,0-12.99-1.02-18.69.27-6.54,1.47-11.71,6.34-9.97,13.82,1.82,7.84,8.49,7.27,14.67,5.88,5.55-1.25,10.98-3,16.53-4.19,13.14-2.81,22.81,1.35,26.08,11,3.64,10.71-1.81,22.47-13.02,28.5-4.21,2.26-8.23,4.88-12.3,7.31Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M819.29,960.86c-6.34,3.96-11.65,7.27-17.71,11.06-5.91-29.05-32.83-31.95-52.49-45.01,5.5-3.56,11.44-7.41,15.33-9.92,11.81,7.21,22.14,13.52,33.11,20.22,3.38-18.51,1.07-32.86-5.72-37.71,3.3-1.95,6.41-3.79,9.87-5.84,1.69,23.81-7.02,49.39,17.62,67.21Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M191.34,899.8c-2.18-2.33-5.57-5.96-8.37-8.96,8.02-7.37,15.81-14.03,22.92-21.37,2.16-2.22,2.41-6.29,3.54-9.52-4.03-.09-9.22-1.82-11.87.05-8.44,5.94-15.93,13.22-23.79,19.98.5.93,1,1.85,1.49,2.78-3.53-3.81-7.07-7.61-10.39-11.2,6.02-5.34,11.3-10.56,17.14-15.05,5.78-4.45,12.11-8.17,18.2-12.21-.79-1.55-1.58-3.09-2.37-4.64,2.71,4.29,5.17,8.77,8.25,12.77,1.3,1.69,3.84,2.53,5.93,3.49,9.86,4.48,12.11,13.5,4.45,21.25-7.68,7.77-16.22,14.68-25.13,22.63Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M135.12,696.5c-9.88-3.14-17.43.64-19.64,7.42-4.86,0-9.06-.93-12.63.18-10.11,3.12-19.97,7.07-30.59,10.93-1.44-4.05-3.19-8.96-5.01-14.09,18.52-1.88,37.55-10.67,39.28-17.55,3.33.72,6.78,2.42,9.94,1.99,12.42-1.71,20.69,4.12,23,16.42.69,3.68,3.36,7.02,3.92,10.69.48,3.18-.74,6.62-1.21,9.94-3.25-.81-7.84-.56-9.48-2.64-4.45-5.65-2.9-17.67,2.42-23.29Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M555.31,500c-17.89,2.19-17.96,2.19-18.34-13.4-.24-10.09-1.21-20.3-.08-30.25,1.14-9.98,4.49-19.71,8.07-29.33,16.49,23.05,7.54,48.4,10.34,72.98Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M688.75,1021.53c-3.45.79-8.44,3.65-11.55,2.27-5.42-2.4-8.02-.37-11.42,2.77-4.49,4.15-9.64,6.87-14.99,1.53-5.24-5.22-2.79-10.6,1.04-15.23,3.2-3.88,6.94-7.35,10.69-10.74,5.22-4.72,4.21-10.23.29-14.15-2-2.01-7.13-.9-12.9-1.37,2.16,4.43,3.28,5.97,3.64,7.66.45,2.16,1.21,5.66.2,6.43-1.89,1.44-5.52,2.46-7.39,1.54-1.88-.93-3.61-4.48-3.48-6.77.4-6.72,10.89-13.94,20.18-14.63,8.15-.6,13.31,3.01,15.42,10.92,1.47,5.5,2.67,11.08,4.43,16.48,1.27,3.9,3.27,7.55,5.83,13.3ZM669.8,1003.86c-1.15-.19-2.3-.38-3.46-.57-5.09,3.44-7.84,8.22-5.47,14.52.53,1.41,4.47,1.53,6.84,2.24.7-5.4,1.39-10.8,2.09-16.2Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M510.9,1041.61c-5.11,3.05-10.09.55-14.46-5.76-2.63,1.08-5.24,3.09-7.77,2.99-4.57-.17-9.09-1.6-13.63-2.51,1.35-4.72,1.34-10.79,4.37-13.82,4-3.99,10.37-5.64,15.76-8.21,4.58-2.18,7.48-10.69,4.08-14.08-1.82-1.82-5.48-2.19-8.33-2.28-.65-.02-1.42,3.83-2.14,5.9-.82,2.35-1.12,6.14-2.62,6.71-2.05.77-6.46-.18-7.25-1.73-1.12-2.18-.84-6.24.57-8.34,4.18-6.22,16.24-8.2,24.47-4.95,7.81,3.08,9.79,8.91,8.98,16.74-.95,9.21-1.3,18.48-2.01,29.33ZM500.4,1018.61c-1.08-.59-2.15-1.18-3.23-1.76-5.06,2.53-10.75,4.84-10.05,12.2.15,1.59,3.91,2.83,6.01,4.23,2.42-4.89,4.84-9.77,7.27-14.66Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M302.41,930.79c-2.63,3.08-3.02,3.57-3.44,4.02-2.39,2.58-4.78,5.16-7.17,7.74-1.65-2.02-3.29-4.03-4.94-6.05,3.19-2.47,6.13-6.54,9.63-7.1,8.77-1.41,15.92,2.87,21.24,9.83,4.83,6.34,1.24,11.9-2.04,17.41-2.26,3.79-4.75,7.47-6.65,11.43-1.77,3.68-2.89,7.68-5.16,13.94-3.23-1.95-9.14-3.44-10.37-6.7-2.11-5.62-5.43-5.98-9.99-6.22-5.77-.3-11.11-2-11.2-8.93-.09-7.05,5.02-9.87,11.2-10.4,5.01-.43,10.14-.44,15.12.13,7.37.85,10.04-4.23,10.49-9.42.24-2.78-3.98-5.96-6.72-9.69ZM302.46,955.63c-.74-.84-1.47-1.68-2.21-2.52-5.78-.08-11.98-.32-14.08,6.89-.46,1.57,3.09,4.3,4.79,6.5,3.83-3.62,7.66-7.24,11.49-10.86Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M1013.45,604.07c15.02.12,25.86,9.53,25.51,22.13-.35,12.54-9.7,20.56-23.61,20.27-13.54-.29-24.47-9.73-24.6-21.24-.14-12.7,9.06-21.27,22.71-21.15ZM1003.22,615.02c-2.52,1.89-5.86,4.4-9.2,6.92,3.1,2.89,5.75,7.22,9.4,8.37,7.4,2.32,15.31,3.52,23.08,3.91,2.88.14,5.94-3.27,8.92-5.07-2.04-2.74-3.56-7.03-6.23-7.94-7.64-2.62-15.76-3.88-25.96-6.19Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M569.5,267.31h-4.6v-53.4c6.54-1.85,12.78-1.01,17.97,6.54,5.81,8.46,12.7,16.17,20.8,26.3v-33.04c1.01-.06,3.04-.19,4.18-.26v52.77l-2.57,1.07c-11.35-13.79-22.7-27.57-35.79-43.47v43.48Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M160.22,852.53c-9.28,1.62-15.75-2.33-19.68-10.02-4.04-7.93-2.44-15.93,4.03-21.64,5.06-4.46,11.26-8.19,17.59-10.5,7.45-2.72,17.31,2.58,20.8,9.53,3.57,7.14.92,15.34-8.39,24.05-5.13-6.16-10.24-12.29-15.29-18.36-13.4,9.9-12.88,19.18.95,26.94ZM170.94,835.07c3.64-5.56,6.45-9.35,8.51-13.51.37-.75-2.59-4.53-3.61-4.37-4.81.78-9.47,2.44-15.66,4.21,4.08,5.18,6.51,8.26,10.76,13.67Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M917,838.04c-5.11,6.27-9.98,12.25-14.78,18.15,11.76,11.32,21.67,8.78,25.71-5.79,4.54,12.25-3.01,24.22-15.59,24.73-11.71.47-25.93-11.89-27.01-23.69-.71-7.78,1.3-14.72,8.75-18.4,8.38-4.14,15.73-1.38,22.91,5ZM897.51,857.01c4.09-5.47,8.88-9.61,8.13-11.37-1.49-3.47-5.83-6.02-9.5-8.02-.88-.48-5.64,3.26-5.39,4.12,1.28,4.43,3.62,8.55,6.75,15.27Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M543.45,1043.14h-13.83v-65.66c14.02-1.81,13.93-1.81,13.85,12.89-.1,17.52-.02,35.04-.02,52.77Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M551.29,267.44h-14.76c-2.76-15.07-5.36-16.98-20.15-15.11-9.4,1.19-7.49,8.3-7.51,14.96h-7.24c8.23-17.71,16.37-35.2,25.32-54.47,8.47,19,16.19,36.33,24.34,54.62ZM523.2,233.05c-.86-.07-1.72-.13-2.58-.2-2.32,4.97-4.64,9.94-7.5,16.07h16.66c-2.44-5.88-4.51-10.88-6.58-15.88Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M116.01,788.07c-15.69-1.62-23.25-15.01-16.69-27.32,5.77-10.82,21.33-16.97,32.8-12.96,8.84,3.09,14.8,13.68,11.78,22.06-.94,2.6-3.67,4.93-6.16,6.38-.97.57-5.05-1.53-5.11-2.56-.16-2.74.9-5.64,1.85-8.34,1.04-2.94,2.51-5.72,3.8-8.57-3.8-.37-8.09-2.03-11.3-.82-4.86,1.83-9.5,5.11-13.29,8.75-8.76,8.43-8.14,14.98,2.32,23.39Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M1015.53,692.29c6.88,10.28,3.14,23.11-8.88,27.52-14.96,5.5-33.99-5.93-34.94-20.98-.57-9.08,5.56-18.31,12.85-18.11,2.43.07,4.77,3.4,7.15,5.24-2.02,1.89-3.8,4.22-6.15,5.52-1.39.77-3.73-.42-5.57-.18-5.51.72-6.07,3.84-3.27,7.97,4.94,7.3,25.79,11.25,32.23,5.36,3.24-2.96,4.44-8.14,6.57-12.34Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M236.91,930.4c-8.78,1.55-15.5-.41-20.53-7.07-4.89-6.47-4.77-13.28-1.44-20.48,4.16-8.97,11.01-14.54,20.53-16.58,10.2-2.18,21.82,5.81,22.21,15.43.11,2.74-1.63,5.94-3.44,8.19-.76.95-5.29.63-5.69-.24-1.18-2.55-1.44-5.64-1.53-8.54-.1-3.08.41-6.18.65-9.27-3.66,1.04-7.67,1.47-10.88,3.3-2.56,1.46-4.4,4.45-6.15,7.03-8.38,12.42-6.75,20.41,6.28,28.23Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M546.23,390.44c-12.31-16.08-12.86-27.22-2.82-34.08-4.76-3.85-9.72-7.86-14.67-11.87.57-1.08,1.15-2.17,1.72-3.25,5.09.65,10.18,1.83,15.27,1.81,5.23-.02,10.46-1.22,15.68-1.91l2.08,2.52c-4.2,4.07-8.4,8.15-12.69,12.3,8.1,11.3,7.46,17.46-4.58,34.48Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M538.08,590.74c0-15.12.13-28.62-.06-42.12-.09-6.41,4.08-5.89,8.29-5.83,4.21.05,8.35-.35,8.15,6.04-.37,11.96.22,23.98-.67,35.89-.82,10.96-9.16,4.53-15.71,6.02Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M909.28,815.01c2.88-2.8,3.11-12.64,13.63-7.92,0-4.63-.62-8.78.24-12.6.49-2.15,3.17-4.77,5.32-5.28,1.69-.4,5.44,1.81,5.79,3.4.46,2.09-.85,5.95-2.58,6.91-7.94,4.38-5.57,9.37-.73,13.46,7.06,5.97,14.9,11.03,23.14,17.01-2.27,3.14-5.5,7.59-7.93,10.95-12.46-8.76-24.31-17.09-36.88-25.93Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M931.75,782.26c2.87-5.36,5.51-10.3,7.35-13.72,10,1.53,19.13,2.92,28.26,4.31.54-.99,1.08-1.97,1.62-2.96-6.12-6.49-12.24-12.97-19.08-20.22.13-.21,1.39-2.25,2.86-4.63,10.15,13.45,19.92,26.41,31.33,41.54-18.66-1.54-34.96-2.89-52.34-4.33Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M626.12,213.79h13.24v53.49h-13.24v-53.49Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M518.72,407.54c-2.83,0-5.68.16-8.5-.03-28.38-1.9-52.34,7.16-70.26,29.56-8.27,10.34-16.98,2.39-25.81,1.77,18.03-9.46,35.98-19.08,54.12-28.33,19.35-9.86,27.28-10.41,50.45-2.98Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M574.36,1042.72h-16v-44.74h12.92c.99,14.46,2,29.08,3.08,44.74Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M998.75,752.41c-14.07-6.17-27.28-11.96-41.49-18.2,1.21-2.77,3.02-6.92,5.11-11.72,13.97,5.84,27.4,11.45,41.79,17.47-1.67,3.84-3.85,8.84-5.42,12.45Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M680.14,439.72c-13.33,2.14-22.57,5.4-32.27-7.1-16.48-21.25-41.01-26.88-67.23-25.24-2.38.15-4.76.27-7.15-.82,15.08-.74,29.64-8.57,45.42,1.28,17.08,10.66,35.83,18.65,53.84,27.82,1.82.93,3.58,1.97,7.38,4.06Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M85.25,746.89c-1.66-3.86-3.74-8.69-5.33-12.39,14.23-6.06,27.8-11.84,41.52-17.68q6.1,11.59-8.16,17.73c-9.12,3.95-18.19,8-28.04,12.34Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M982.84,665.72q2.32-12.52,14.62-9.48c10.58,2.6,21.16,5.18,32.26,7.89-1.2,4.59-2.53,9.64-3.53,13.44-14.59-3.99-28.59-7.81-43.35-11.84Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M161.31,792.55c-13.25,8.74-24.98,16.47-37.44,24.68-2.31-3.55-5.12-7.88-7.26-11.17,13.02-8.58,25.35-16.72,38.07-25.11,1.59,2.78,4.01,7.01,6.64,11.6Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M271.69,908.88q11.33,6.73,3.4,17.8c-6.14,8.58-12.26,17.17-18.86,26.43-3.05-2.13-7.33-5.12-11.05-7.72,8.85-12.18,17.46-24.05,26.51-36.51Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M539.34,626.85h13.2c0,12.73.24,25.18-.31,37.59-.07,1.54-4.36,4.1-6.59,3.99-2.17-.11-5.94-2.96-6.02-4.74-.54-11.9-.28-23.84-.28-36.85Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M539.72,736.27c0-12.45-.26-24.37.27-36.25.08-1.79,3.9-4.82,5.87-4.73,1.98.1,5.35,3.31,5.44,5.27.52,11.61.25,23.25.25,35.7h-11.83Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M550.29,778.75c0,4.38.43,8.82-.21,13.11-.26,1.74-2.83,3.14-4.34,4.69-1.42-1.6-3.97-3.15-4.06-4.82-.44-8.11-.45-16.28-.04-24.39.09-1.74,2.52-3.36,3.88-5.03,1.61,1.72,4.2,3.24,4.61,5.22.75,3.6.21,7.47.21,11.22-.02,0-.04,0-.06,0Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M347.63,1007.05c-1.52-4.33-3.05-8.66-4.57-12.99,3.6-.9,8.06-3.58,10.63-2.34,6.9,3.33,6.68,10.24,2.64,15.32-3.91,4.92-9.81,8.27-14.84,12.31-.59-.74-1.18-1.48-1.77-2.23,3.72-2.97,7.45-5.95,11.17-8.92-1.09-.39-2.18-.77-3.27-1.16Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M567.25,411.44c-5.04,3.96-10.08,7.92-15.13,11.88-.93-.27-1.86-.54-2.79-.81v-17.42c5.98.71,11.95,1.41,17.93,2.12,0,1.41,0,2.82,0,4.23Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M540.43,421.5c-4.84-3.74-9.69-7.49-14.53-11.23.06-1.14.13-2.28.19-3.43,5.32-.57,10.64-1.13,15.95-1.7.42,5.19.84,10.38,1.27,15.56-.96.26-1.92.53-2.88.79Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M944.98,711.22c3.88,4.88,7.7,7.6,7.58,10.11-.13,2.52-3.91,6.21-6.64,6.77-1.88.39-6.39-3.48-6.62-5.77-.28-2.72,2.76-5.79,5.68-11.11Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M969.86,645.83c2.88,4.38,6.37,7.41,5.85,9.42-.73,2.82-4.42,4.88-6.85,7.27-2.17-2.68-5.62-5.16-6.07-8.1-.3-1.99,3.62-4.62,7.07-8.6Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M182.01,779.48c-5.62,3.03-8.65,5.98-11.34,5.7-2.18-.23-5.6-4.47-5.45-6.73.16-2.38,3.99-6.39,6.07-6.3,2.79.12,5.44,3.49,10.73,7.34Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M574.61,981.64c-4.95,3.63-7.83,7.36-10.3,7.11-2.62-.27-6.09-4.02-6.77-6.84-.4-1.65,3.8-6.11,6.15-6.29,2.71-.2,5.67,2.93,10.92,6.01Z"
        />
        <path
          fill={lightMode ? '#0d06f5' : '#ffffff'}
          d="M296.25,900.44c-4.9,2.91-7.79,5.98-10.5,5.82-2.23-.13-4.23-4.09-6.33-6.35,2.5-2.27,4.74-5.82,7.57-6.37,1.97-.38,4.74,3.35,9.26,6.9Z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
}

LogoMedicoreGrumeba.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  lightMode: PropTypes.bool,
};
