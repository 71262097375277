import { Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';

import { authRoutes } from './auth';
import { doctorRoutes } from './doctor';
import { patientRoutes } from './patient';
import { medicoreRoutes } from './medicore';
import { HomePage, mainRoutes } from './main';
import { emergencyRoutes } from './emergency';
import { medicoreFinanceRoutes } from './finance';
import { medicoreInventoryRoutes } from './inventory';
import { medicoreInsuranceRoutes } from './insurance';
import { patientPortalRoutes } from './portal-patient';
import { medicoreHumanResourcesRoutes } from './human-resources';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...medicoreRoutes,

    // Patient routes
    ...patientRoutes,

    // Emergency routes
    ...emergencyRoutes,

    // Inventory routes
    ...medicoreInventoryRoutes,

    // Finance routes
    ...medicoreFinanceRoutes,

    // Main routes
    ...mainRoutes,

    // Insurance routes
    ...medicoreInsuranceRoutes,

    // Insurance routes
    ...medicoreHumanResourcesRoutes,

    // Doctor routes
    ...doctorRoutes,

    // Patient portal routes
    ...patientPortalRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
