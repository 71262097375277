import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 60,
        height: 60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1080 1080"
      >
        <rect
          fill="#4057e3"
          x="140.12"
          y="159.28"
          width="795.12"
          height="795.12"
          rx="138.44"
          ry="138.44"
        />
        <g>
          <path
            fill="#fff"
            d="M722.49,629.74l-67.53-67.53c-9.16-9.16-24.01-9.16-33.17,0l-67.84,67.84c-8.99,8.99-23.56,8.99-32.55,0l-67.84-67.84c-9.16-9.16-24.01-9.16-33.17,0l-67.53,67.53c-9.16,9.16-9.16,24.01,0,33.17l84.11,84.11,58.56,58.56c23.27,23.27,61,23.27,84.27,0l58.56-58.56,84.11-84.12c9.16-9.16,9.16-24.01,0-33.17Z"
          />
          <path
            fill="#fff"
            d="M648.29,467.74v-102.31c0-9.24-4.93-17.78-12.93-22.4l-84.74-48.93c-8-4.62-17.87-4.62-25.87,0l-84.74,48.93c-8,4.62-12.93,13.16-12.93,22.4v102.31c0,6.72,2.62,13.18,7.3,18.01l84.74,87.41c10.16,10.48,26.98,10.48,37.15,0l84.74-87.41c4.68-4.83,7.3-11.28,7.3-18.01Z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
