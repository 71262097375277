import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

export default function LogoMedicore({ disabledLink = false, sx, ...other }) {
  const logo = (
    <Box
      component="div"
      sx={{
        width: 210,
        height: 60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1482.89 500">
        <path
          fill="#fff"
          d="M514.45,240.95v95.44h-31.94v-95.44c0-10.08-8.2-18.29-18.29-18.29s-18.29,8.2-18.29,18.29v95.44h-31.94v-95.44c0-10.08-8.2-18.29-18.29-18.29s-18.28,8.2-18.28,18.29v95.44h-31.94v-95.44c0-27.7,22.53-50.23,50.22-50.23,13.25,0,25.27,5.19,34.25,13.6,8.98-8.41,21.01-13.6,34.25-13.6,27.7,0,50.23,22.53,50.23,50.23Z"
        />
        <path
          fill="#fff"
          d="M560.89,274.45c3.8,18.48,18.69,32.2,36.48,32.2,13.3,0,25.02-7.71,31.62-19.37h32.89c-8.87,28.87-34.42,49.73-64.51,49.73-37.43,0-67.83-32.25-67.83-72s30.4-72,67.83-72c30.09,0,55.69,20.9,64.51,49.78,2.16,6.97,3.33,14.46,3.33,22.22,0,3.22-.21,6.33-.64,9.45h-103.67ZM628.98,242.78c-6.54-11.66-18.26-19.43-31.62-19.43s-25.07,7.76-31.62,19.43h63.24Z"
        />
        <path
          fill="#fff"
          d="M808.8,146.08v187.76h-31.36v-5.17c-9.98,5.96-21.48,9.4-33.68,9.4-37.79,0-68.52-32.62-68.52-72.69s30.72-72.63,68.52-72.63c12.2,0,23.7,3.38,33.68,9.34v-56h31.36ZM777.45,265.19s0-.11,0-.15c0-1.01-.06-2.12-.15-3.27-.04-.56-.1-1.12-.17-1.67-.04-.35-.08-.7-.13-1.06-.5-3.76-1.43-7.35-2.74-10.7-3.53-9.43-10.41-18.79-22.42-22.43-2.52-.83-5.15-1.33-7.85-1.48-.07,0-.13-.02-.2-.03-.1,0-.19,0-.29,0-.43-.01-.86-.07-1.29-.07-.32,0-.63.05-.95.06-.86,0-1.71.06-2.55.15-.07.01-.15.02-.22.03-4.69.52-9.1,2.16-13.05,4.7-.06.03-.11.08-.17.11-.84.55-1.68,1.12-2.48,1.75-.64.49-1.25,1.03-1.86,1.57-.21.19-.43.36-.64.55-8.13,7.52-13.35,19.1-13.35,32.11h0c0,.06,0,.1,0,.14.06,21.75,14.65,39.46,33.07,40.77h.02c.72.06,1.43.13,2.16.13.23,0,.46-.04.69-.04.3,0,.59.04.89.03.66-.02,1.28-.13,1.91-.19.06,0,.11-.01.17-.02,6.78-.66,12.36-3.45,16.82-7.45,8.93-7.44,14.77-19.59,14.77-33.36,0-.05,0-.1,0-.17Z"
        />
        <path fill="#fff" d="M831.93,336.38v-145.6h31.39v145.6h-31.39Z" />
        <path
          fill="#fff"
          d="M982.74,281.26h32.88c-6.81,32.46-34.21,56.8-66.88,56.8-37.8,0-68.52-32.57-68.52-72.64s30.72-72.69,68.52-72.69c32.67,0,60.07,24.39,66.88,56.85h-32.88c-5.6-14.78-18.74-25.18-33.99-25.18-20.32,0-36.84,18.42-36.84,41.01s16.52,40.96,36.84,40.96c15.25,0,28.39-10.4,33.99-25.13Z"
        />
        <path
          fill="#fff"
          d="M1162.69,263.52c0,40.07-30.74,72.66-68.51,72.66s-68.51-32.59-68.51-72.66,30.73-72.66,68.51-72.66,68.51,32.59,68.51,72.66ZM1131.02,263.51c0-22.6-16.52-40.99-36.84-40.99s-36.84,18.39-36.84,40.99,16.52,41,36.84,41,36.84-18.39,36.84-41Z"
        />
        <path fill="#fff" d="M1252.35,190.78v31.81h-41.7v113.78h-31.39v-145.59h73.08Z" />
        <path
          fill="#fff"
          d="M1288.11,274.45c3.81,18.48,18.69,32.2,36.48,32.2,13.31,0,25.02-7.71,31.62-19.37h32.89c-8.87,28.87-34.42,49.73-64.51,49.73-37.42,0-67.83-32.25-67.83-72s30.41-72,67.83-72c30.09,0,55.69,20.9,64.51,49.78,2.17,6.97,3.33,14.46,3.33,22.22,0,3.22-.21,6.33-.64,9.45h-103.67ZM1356.2,242.78c-6.54-11.66-18.26-19.43-31.62-19.43s-25.07,7.76-31.62,19.43h63.24Z"
        />
        <path
          fill="#fff"
          d="M856.28,177.99c5.75,0,10.42-4.66,10.42-10.42v-24.13s-24.35,0-24.35,0c-5.75,0-10.42,4.66-10.42,10.42v24.13s24.35,0,24.35,0Z"
        />
        <path
          fill="#fff"
          d="M271.79,293.01l-32.52-32.52c-4.41-4.41-11.56-4.41-15.97,0l-32.67,32.67c-4.33,4.33-11.35,4.33-15.68,0l-32.67-32.67c-4.41-4.41-11.56-4.41-15.97,0l-32.52,32.52c-4.41,4.41-4.41,11.56,0,15.97l40.51,40.51,28.2,28.2c11.21,11.21,29.38,11.21,40.58,0l28.2-28.2,40.51-40.51c4.41-4.41,4.41-11.56,0-15.97Z"
        />
        <path
          fill="#fff"
          d="M236.06,214.99v-49.27c0-4.45-2.37-8.56-6.23-10.79l-40.81-23.56c-3.85-2.23-8.6-2.23-12.46,0l-40.81,23.56c-3.85,2.23-6.23,6.34-6.23,10.79v49.27c0,3.24,1.26,6.35,3.51,8.67l40.81,42.1c4.89,5.05,12.99,5.05,17.89,0l40.81-42.1c2.25-2.32,3.51-5.43,3.51-8.67Z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
}

LogoMedicore.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};
